import React, { useCallback, useEffect, useState } from "react";

import { Box } from "@mui/system";
import { Button, MenuItem, Select, TextField } from "@mui/material";

import { SubContainer } from "./styles";
import Snackbar from "../../components/Snackbar";
import { useUser } from "../../contexts/UserContext";
import Translator from "../i18nComponents/Translator";

export default function ReaderParams() {

  const { handleReaderParams, saveReaderParams, readerConfig, userId } =
    useUser();

  const [timezone, setTimezone] = useState(null);
  const [maxTrades, setMaxTrades] = useState(null);
  const [hourStart, setHourStart] = useState(null);
  const [hourEnd, setHourEnd] = useState(null);
  const [someStateOpen, setSomeStateOpen] = useState(false);

  const handleSaveParameter = useCallback(async () => {
    //
    setSomeStateOpen((prevState) => !prevState);
    //
    await saveReaderParams(timezone, maxTrades, hourStart, hourEnd);
  });

  const handleSetReadParams = (data) => {
    setTimezone(data.timerzone);
    setMaxTrades(data.maxtrades);
    setHourStart(data.hourStart);
    setHourEnd(data.hourEnd);
  };

  useEffect(() => {
    if (readerConfig) handleSetReadParams(readerConfig);
  }, [readerConfig]);

  useEffect(() => {
    if (userId) handleReaderParams();
  }, [userId]);

  /*
  useEffect(() => {
    const get = () => readerConfig ?
      handleSetReadParams(readerConfig) : handleReaderParams()

    if (readerConfig || userId) {
      get();
    }
  }, [readerConfig, userId]);
  */

  return (
    <>

      <SubContainer>
        <div><Translator path="componentes.ReaderParams.textFusoHorario"/></div>
        <Box
          component="form"
          sx={{
            maxWidth: "100%",
          }}
          noValidate
          autoComplete="off"
        >
          <TextField
            fullWidth
            sx={{ marginBottom: "15px" }}
            value={timezone}
            onChange={(e) => {
              setTimezone(e.target.value);
            }}
            variant="filled"
          />
        </Box>
      </SubContainer>

      <SubContainer>
        <div><Translator path="componentes.ReaderParams.textOrdemSimultaneas"/></div>
        <Box
          component="form"
          sx={{
            maxWidth: "100%",
          }}
          noValidate
          autoComplete="off"
        >
          <TextField
            fullWidth
            sx={{ marginBottom: "15px" }}
            type="number"
            value={maxTrades}
            onChange={(e) => {
              setMaxTrades(e.target.value);
            }}
            variant="filled"
          />
        </Box>
      </SubContainer>

      <SubContainer>
        <div><Translator path="componentes.ReaderParams.textFiltroHorarioInicio"/></div>
        <Box
          component="form"
          sx={{
            maxWidth: "100%",
          }}
          noValidate
          autoComplete="off"
        >
          <TextField
            fullWidth
            sx={{ marginBottom: "15px" }}
            type="time"
            value={hourStart}
            onChange={(e) => {
              setHourStart(e.target.value);
            }}
            variant="filled"
          />
        </Box>
      </SubContainer>

      <SubContainer>
        <div><Translator path="componentes.ReaderParams.textFiltroHorarioFinal"/></div>
        <Box
          component="form"
          sx={{
            maxWidth: "100%",
          }}
          noValidate
          autoComplete="off"
        >
          <TextField
            fullWidth
            sx={{ marginBottom: "15px" }}
            type="time"
            value={hourEnd}
            onChange={(e) => {
              setHourEnd(e.target.value);
            }}
            variant="filled"
          />
        </Box>
      </SubContainer>

      <Snackbar
        type="success"
        open={someStateOpen}
        autoHideDuration={2000}
        onClose={() => setSomeStateOpen(false)}
      >
       <Translator path="snackbarSucess"/>
      </Snackbar>

      <Button
        sx={{ width: "270px" }}
        onClick={() => handleSaveParameter()}
        variant="contained"
      ><Translator path="buttonSalvar"/></Button>
    </>
  );
}
