import styled from "styled-components";

export const Container = styled.div``;

export const Title = styled.div`
  font-size: 30px;
  font-weight: bold;
  letter-spacing: 1.5px;
  margin-bottom: 20px;
`;
export const TitleRed = styled.div`
  font-size: 30px;
  font-weight: bold;
  letter-spacing: 1.5px;
  margin-bottom: 20px;
  color: red;
`;
export const SubTitle = styled.div`
  font-size: 24px;
  font-weight: normal;
  letter-spacing: 1.5px;
  margin-bottom: 20px;
`;
export const Divider = styled.div`
  margin-top: 5px;
  margin-bottom: 15px;
  border-bottom: 1px solid gray;
`;
export const CardContainer = styled.div`
  width: 270px;
  border: 1px solid gray;
  padding: 15px 10px;
  border-radius: 8px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  input {
    border: 0.5px solid gray;
    border-radius: 4px;
    height: 30px;
    width: 280px;

    padding: 4px;
  }
`;
export const Button = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 270px;
  padding: 4px 6px;
  border: 1px solid gray;
  border-radius: 4px;

  margin: 8px 0;

  cursor: pointer;

  a {
    text-decoration: none;
    color: white;
  }
`;
