import React from "react";

import { Button, Container, Divider, Title } from "./styles";
import { useUser } from "../../contexts/UserContext";

import Translator from "../../components/i18nComponents/Translator";

export default function CopySignals() {

  const { resetDelete, resetReader } = useUser();

  const handleDisconect = () => {
    resetDelete();
    alert("Comando enviado");
  };

  const handleReset = () => {
    resetReader();
    alert("Comando enviado");
  };

  return (
    <Container>
      <Title><Translator path="paginas.copySignals.Title"/></Title>
      <Divider />

      <Button>
        <a href="/addphone"><Translator path="paginas.copySignals.linkAddPhone"/></a>
      </Button>

      <Button>
        <a href="/channels-list"><Translator path="paginas.copySignals.linkChannelList"/></a>
      </Button>

      <Button>
        <a href="/configure-channels"><Translator path="paginas.copySignals.linkConfigSala"/></a>
      </Button>

      <Button>
        <a href="/reader-params"><Translator path="paginas.copySignals.linkParametros"/></a>
      </Button>

      <Button>
        <a href="/copy-trader-sinais"><Translator path="paginas.copySignals.linkCopyTrader"/></a>
      </Button>

      <Button>
        <div onClick={() => handleReset()}><Translator path="paginas.copySignals.buttonReset"/></div>
      </Button>

      <Button>
        <div onClick={() => handleDisconect()}><Translator path="paginas.copySignals.buttonDeletar"/></div>
      </Button>

    </Container>
  );
}
