import styled from "styled-components";

export const Container = styled.div``;

export const SubContainer = styled.div`
  display: grid;
  gap: 10px;
  margin-bottom: 20px;

  @media (min-width: 766px) {
    grid-template-columns: 350px;
  }
  @media (min-width: 1026px) {
    grid-template-columns: 350px 350px;
  }
  @media (min-width: 1286px) {
    grid-template-columns: 350px 350px 350px;
  }
`;
export const CardContainer = styled.div`
  width: 350px;
  border: 1px solid gray;
  padding: 15px 30px;
  border-radius: 8px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  input {
    border: 0.5px solid gray;
    height: 25px;
  }
`;
export const Divider = styled.div`
  margin-top: 5px;
  margin-bottom: 15px;
  border-bottom: 1px solid gray;
`;
export const Title = styled.div`
  font-size: 30px;
  font-weight: bold;
  letter-spacing: 1.5px;
  margin-bottom: 20px;
`;
export const SubTitle = styled.div`
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 1.5px;
  margin-left: 8px;
`;
export const InputContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 0 5px 0;
  gap: 8px;

  input {
    border: 0.5px solid gray;
    height: 25px;
  }
`;
export const Button = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 270px;
  padding: 4px 6px;
  border: 1px solid gray;
  border-radius: 4px;

  margin: 8px 0;

  cursor: pointer;

  a {
    text-decoration: none;
    color: white;
  }
`;
