/* eslint-disable no-unused-vars */
import React from "react";
import ReaderParams from "../../components/ReaderParams";

import Translator from "../../components/i18nComponents/Translator"

import { Container, Title, Divider } from "./styles";

export default function ReaderConfig() {
  return (
    <Container>
      <Title><Translator path="paginas.readerConfig.titlePagina"/></Title>
      <Divider />
      <ReaderParams />
      <Divider />

    </Container>
  );
}
