/* eslint-disable no-unused-vars */
import React from "react";
import GlobalParamsScreen from "../../components/Parameters";
import LossRecoverScreen from "../../components/LossRecover";

import { Container, Title, Divider } from "./styles";
import Translator from "../../components/i18nComponents/Translator";

export default function GeralConfig() {
  return (
    <Container>
      <Title><Translator path="paginas.geralConfig.titleGerais"/></Title>
      <Divider />
      <GlobalParamsScreen />
      <Divider />

      <Title><Translator path="paginas.geralConfig.titleRecuperacao"/></Title>
      <Divider />
      <LossRecoverScreen />

    </Container>
  );
}
