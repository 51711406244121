/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Box } from "@mui/system";
import { Button, TextField } from "@mui/material";
import { useUser } from "../../contexts/UserContext";
import { Container, Title, Divider, SubContainer } from "./styles";
import Translator from "../../components/i18nComponents/Translator";

export default function ProxyConfig() {

  const [proxyIP, setProxyIP] = useState(null);
  const [proxyPorta, setProxyPorta] = useState(null);
  const [proxyLogin, setProxyLogin] = useState(null);
  const [proxyPass, setProxyPass] = useState(null);

  const { sendProxyParams } = useUser();

  const handleSaveProxy = async (ip, porta, login, password) => {

    const response = await sendProxyParams(ip, porta, login, password);

    if (response)
      alert("Parametros salvos");
  };

  return (
    <Container>
      <Title><Translator path="paginas.proxyConfig.Title"/></Title>
      <Divider />

      <SubContainer>
        <div><Translator path="paginas.proxyConfig.textProxyendereco"/></div>
        <Box
          component="form"
          sx={{
            maxWidth: "100%",
          }}
          noValidate
          autoComplete="off"
        >
          <TextField
            fullWidth
            sx={{ marginBottom: "15px" }}
            value={proxyIP}
            onChange={(e) => {
              setProxyIP(e.target.value.trim());
            }}
            variant="filled"
          />
        </Box>
      </SubContainer>

      <SubContainer>
        <div><Translator path="paginas.proxyConfig.textProxyPorta"/></div>
        <Box
          component="form"
          sx={{
            maxWidth: "100%",
          }}
          noValidate
          autoComplete="off"
        >
          <TextField
            fullWidth
            sx={{ marginBottom: "15px" }}
            value={proxyPorta}
            type="number"
            onChange={(e) => {
              setProxyPorta(e.target.value.trim());
            }}
            variant="filled"
          />
        </Box>
      </SubContainer>

      <SubContainer>
        <div>Login:</div>
        <Box
          component="form"
          sx={{
            maxWidth: "100%",
          }}
          noValidate
          autoComplete="off"
        >
          <TextField
            fullWidth
            sx={{ marginBottom: "15px" }}
            value={proxyLogin}
            onChange={(e) => {
              setProxyLogin(e.target.value.trim());
            }}
            variant="filled"
          />
        </Box>
      </SubContainer>

      <SubContainer>
        <div><Translator path="senha"/></div>
        <Box
          component="form"
          sx={{
            maxWidth: "100%",
          }}
          noValidate
          autoComplete="off"
        >
          <TextField
            fullWidth
            sx={{ marginBottom: "15px" }}
            value={proxyPass}
            onChange={(e) => {
              setProxyPass(e.target.value.trim());
            }}
            variant="filled"
          />
        </Box>
      </SubContainer>

      <Button
        sx={{ width: "270px" }}
        onClick={() => handleSaveProxy(proxyIP, proxyPorta, proxyLogin, proxyPass)}
        variant="contained"
      ><Translator path="buttonSalvar"/></Button>

    </Container>
  );
}
