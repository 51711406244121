/* eslint-disable no-unused-vars */
import React from "react";
import TraderCard from "../../components/TraderCard";

import { Container, Title, Divider } from "./styles";
import Translator from "../../components/i18nComponents/Translator";

export default function BrokerConfig() {
  return (
    <Container>
      <Title><Translator path="paginas.BrokerConfig.Title"/></Title>
      <Divider />
      <TraderCard />
    </Container>
  );
}
