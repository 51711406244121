import React, { useEffect } from "react";

import { ChannelContainer, Container, Divider, Title } from "./styles";
import { useUser } from "../../contexts/UserContext";
import FollowConfig from "../FollowConfig";
import Translator from "../../components/i18nComponents/Translator";

export default function Channel() {
  const { tradersList, handleGetTraders } = useUser();

  useEffect(() => {
    handleGetTraders();
  }, []);

  return (
    <Container>
      <Title><Translator path="paginas.copyTrader.Title"/></Title>
      <Divider />

      <ChannelContainer>
        {tradersList.map((trader, index) => (
          <FollowConfig trader={trader} key={index} />
        ))}
      </ChannelContainer>
    </Container>
  );
}
