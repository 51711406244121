import { Box, Button, TextField } from "@mui/material";
import React, { useState } from "react";
import { useUser } from "../../contexts/UserContext";
import { useTranslation } from "react-i18next";
import { CardContainer, SubContainer, SubTitle, Divider } from "./styles";
import Translator from "../i18nComponents/Translator";

export default function TraderCard() {
  const { handleSaveOptions } = useUser();

  const [emailIq, setEmailIq] = useState(null);
  const [passIq, setPassIq] = useState(null);
  const [tokenDemo, setTokenDemo] = useState(null);
  const [tokenReal, settokenReal] = useState(null);
  const [emailCapital, setEmailCapital] = useState(null);
  const [passCapital, setPassCapital] = useState(null);
  const [emailQuotex, setEmailQuotex] = useState(null);
  const [passQuotex, setPassQuotex] = useState(null);
  const [emailProcket, setEmailProcket] = useState(null);
  const [passProcket, setPassProcket] = useState(null);
  const [emailMango, setEmailMango] = useState(null);
  const [passMango, setPassMango] = useState(null);
  const [emailExnova, setEmailExnova] = useState(null);
  const [passExnova, setPassExnova] = useState(null);
  const [emailBinomo, setEmailBinomo] = useState(null);
  const [passBinomo, setPassBinomo] = useState(null);
  const [emailBitness, setEmailBitness] = useState(null);
  const [passBitness, setPassBitness] = useState(null);
  const [emailBinolla, setEmailBinolla] = useState(null);
  const [passBinolla, setPassBinolla] = useState(null);
  const [emailOlimp, setEmailOlimp] = useState(null);
  const [passOlimp, setPassOlimp] = useState(null);
  const [emailDayprofit, setEmailDayprofit] = useState(null);
  const [passDayprofit, setPassDayprofit] = useState(null);

  //const [quotexToken, setQuotexToken] = useState(null);
  //const [pocketToken, setPocketToken] = useState(null);
  //const [binomoToken, setBinomoToken] = useState(null);

  const {t} = useTranslation()
  return (
    <SubContainer>

      <CardContainer>
        <SubTitle>IQ option</SubTitle>
        <Box
          component="form"
          sx={{
            maxWidth: "100%",
          }}
          noValidate
          autoComplete="off"
        >
          <TextField
            fullWidth
            sx={{ marginBottom: "15px" }}
            defaultValue={emailIq}
            label={"Email"}
            type={"email"}
            onChange={(e) => setEmailIq(e.target.value.trim())}
            variant="filled"
          />
          <TextField
            fullWidth
            sx={{ marginBottom: "15px" }}
            defaultValue={passIq}
            label={t("senha")}
            type={"password"}
            onChange={(e) => setPassIq(e.target.value.trim())}
            variant="filled"
          />
        </Box>

        <Button
          onClick={() =>
            handleSaveOptions({
              email: emailIq,
              password: passIq,
              key: "iqdata",
            })
          }
          variant="contained"
        >
          <Translator path="buttonSalvar"/>
        </Button>
      </CardContainer>

      <CardContainer>
        <SubTitle>Quotex</SubTitle>
        <Box
          component="form"
          sx={{
            maxWidth: "100%",
          }}
          noValidate
          autoComplete="off"
        >
          <TextField
            fullWidth
            sx={{ marginBottom: "15px" }}
            defaultValue={emailQuotex}
            label={"Email"}
            type="email"
            onChange={(e) => setEmailQuotex(e.target.value.trim())}
            variant="filled"
          />
          <TextField
            fullWidth
            sx={{ marginBottom: "15px" }}
            defaultValue={passQuotex}
            type={"password"}
            label={t("senha")}
            onChange={(e) => setPassQuotex(e.target.value.trim())}
            variant="filled"
          />
        </Box>

        <Button
          onClick={() =>
            handleSaveOptions({
              email: emailQuotex,
              password: passQuotex,
              key: "quotexdata",
            })
          }
          variant="contained"
        >
          <Translator path="buttonSalvar"/>
        </Button>
      </CardContainer>

      <CardContainer>
        <SubTitle>Pocket Option</SubTitle>
        <Box
          component="form"
          sx={{
            maxWidth: "100%",
          }}
          noValidate
          autoComplete="off"
        >
          <TextField
            fullWidth
            sx={{ marginBottom: "15px" }}
            defaultValue={emailProcket}
            label={"Email"}
            type="email"
            onChange={(e) => setEmailProcket(e.target.value.trim())}
            variant="filled"
          />
          <TextField
            fullWidth
            sx={{ marginBottom: "15px" }}
            defaultValue={passProcket}
            type={"password"}
            label={t("senha")}
            onChange={(e) => setPassProcket(e.target.value.trim())}
            variant="filled"
          />
        </Box>

        <Button
          onClick={() =>
            handleSaveOptions({
              email: emailProcket,
              password: passProcket,
              key: "pocketdata",
            })
          }
          variant="contained"
        >
          <Translator path="buttonSalvar"/>
        </Button>
      </CardContainer>

      <CardContainer>
        <SubTitle>Exnova</SubTitle>
        <Box
          component="form"
          sx={{
            maxWidth: "100%",
          }}
          noValidate
          autoComplete="off"
        >
          <TextField
            fullWidth
            sx={{ marginBottom: "15px" }}
            defaultValue={emailExnova}
            label={"Email"}
            type="email"
            onChange={(e) => setEmailExnova(e.target.value.trim())}
            variant="filled"
          />
          <TextField
            fullWidth
            sx={{ marginBottom: "15px" }}
            defaultValue={passExnova}
            type={"password"}
            label={t("senha")}
            onChange={(e) => setPassExnova(e.target.value.trim())}
            variant="filled"
          />
        </Box>

        <Button
          onClick={() =>
            handleSaveOptions({
              email: emailExnova,
              password: passExnova,
              key: "exnovadata",
            })
          }
          variant="contained"
        >
          <Translator path="buttonSalvar"/>
        </Button>
      </CardContainer>

      <CardContainer>
        <SubTitle>DayProfit</SubTitle>
        <Box
          component="form"
          sx={{
            maxWidth: "100%",
          }}
          noValidate
          autoComplete="off"
        >
          <TextField
            fullWidth
            sx={{ marginBottom: "15px" }}
            defaultValue={emailDayprofit}
            label={"Email"}
            type="email"
            onChange={(e) => setEmailDayprofit(e.target.value.trim())}
            variant="filled"
          />
          <TextField
            fullWidth
            sx={{ marginBottom: "15px" }}
            defaultValue={passDayprofit}
            type={"password"}
            label={t("senha")}
            onChange={(e) => setPassDayprofit(e.target.value.trim())}
            variant="filled"
          />
        </Box>

        <Button
          onClick={() =>
            handleSaveOptions({
              email: emailDayprofit,
              password: passDayprofit,
              key: "dayprofitdata",
            })
          }
          variant="contained"
        >
          <Translator path="buttonSalvar"/>
        </Button>
      </CardContainer>

      <CardContainer>
        <SubTitle>Binolla</SubTitle>
        <Box
          component="form"
          sx={{
            maxWidth: "100%",
          }}
          noValidate
          autoComplete="off"
        >
          <TextField
            fullWidth
            sx={{ marginBottom: "15px" }}
            defaultValue={emailBinolla}
            label={"Email"}
            type="email"
            onChange={(e) => setEmailBinolla(e.target.value.trim())}
            variant="filled"
          />
          <TextField
            fullWidth
            sx={{ marginBottom: "15px" }}
            defaultValue={passBinolla}
            type={"password"}
            label={t("senha")}
            onChange={(e) => setPassBinolla(e.target.value.trim())}
            variant="filled"
          />
        </Box>

        <Button
          onClick={() =>
            handleSaveOptions({
              email: emailBinolla,
              password: passBinolla,
              key: "binolladata",
            })
          }
          variant="contained"
        >
         <Translator path="buttonSalvar"/>
        </Button>
      </CardContainer>

      <CardContainer>
        <SubTitle>Binomo</SubTitle>
        <Box
          component="form"
          sx={{
            maxWidth: "100%",
          }}
          noValidate
          autoComplete="off"
        >
          <TextField
            fullWidth
            sx={{ marginBottom: "15px" }}
            defaultValue={emailBinomo}
            label={"Email"}
            type="email"
            onChange={(e) => setEmailBinomo(e.target.value.trim())}
            variant="filled"
          />
          <TextField
            fullWidth
            sx={{ marginBottom: "15px" }}
            defaultValue={passBinomo}
            type={"password"}
            label={t("senha")}
            onChange={(e) => setPassBinomo(e.target.value.trim())}
            variant="filled"
          />
        </Box>

        <Button
          onClick={() =>
            handleSaveOptions({
              email: emailBinomo,
              password: passBinomo,
              key: "binomodata",
            })
          }
          variant="contained"
        >
          <Translator path="buttonSalvar"/>
        </Button>
      </CardContainer>

      <CardContainer>
        <SubTitle>OlympTrade</SubTitle>
        <Box
          component="form"
          sx={{
            maxWidth: "100%",
          }}
          noValidate
          autoComplete="off"
        >
          <TextField
            fullWidth
            sx={{ marginBottom: "15px" }}
            defaultValue={emailOlimp}
            label={"Email"}
            type="email"
            onChange={(e) => setEmailOlimp(e.target.value)}
            variant="filled"
          />
          <TextField
            fullWidth
            sx={{ marginBottom: "15px" }}
            defaultValue={passOlimp}
            type={"password"}
            label={t("senha")}
            onChange={(e) => setPassOlimp(e.target.value)}
            variant="filled"
          />
        </Box>

        <Button
          onClick={() =>
            handleSaveOptions({
              email: emailOlimp,
              password: passOlimp,
              key: "olympdata",
            })
          }
          variant="contained"
        >
         <Translator path="buttonSalvar"/>
        </Button>
      </CardContainer>

      <CardContainer>
        <SubTitle>Capital Bear</SubTitle>
        <Box
          component="form"
          sx={{
            maxWidth: "100%",
          }}
          noValidate
          autoComplete="off"
        >
          <TextField
            fullWidth
            sx={{ marginBottom: "15px" }}
            defaultValue={emailCapital}
            label={"Email"}
            type="email"
            onChange={(e) => setEmailCapital(e.target.value.trim())}
            variant="filled"
          />
          <TextField
            fullWidth
            sx={{ marginBottom: "15px" }}
            defaultValue={passCapital}
            type={"password"}
            label={t("senha")}
            onChange={(e) => setPassCapital(e.target.value.trim())}
            variant="filled"
          />
        </Box>

        <Button
          onClick={() =>
            handleSaveOptions({
              email: emailCapital,
              password: passCapital,
              key: "capitaldata",
            })
          }
          variant="contained"
        >
          <Translator path="buttonSalvar"/>
        </Button>
      </CardContainer>

      <CardContainer>
        <SubTitle>Mango Trade</SubTitle>
        <Box
          component="form"
          sx={{
            maxWidth: "100%",
          }}
          noValidate
          autoComplete="off"
        >
          <TextField
            fullWidth
            sx={{ marginBottom: "15px" }}
            defaultValue={emailMango}
            label={"Email"}
            type="email"
            onChange={(e) => setEmailMango(e.target.value.trim())}
            variant="filled"
          />
          <TextField
            fullWidth
            sx={{ marginBottom: "15px" }}
            defaultValue={passMango}
            type={"password"}
            label={t("senha")}
            onChange={(e) => setPassMango(e.target.value.trim())}
            variant="filled"
          />
        </Box>

        <Button
          onClick={() =>
            handleSaveOptions({
              email: emailMango,
              password: passMango,
              key: "mangodata",
            })
          }
          variant="contained"
        >
          <Translator path="buttonSalvar"/>
        </Button>
      </CardContainer>

      <CardContainer>
        <SubTitle>Binary</SubTitle>
        <Box
          component="form"
          sx={{
            maxWidth: "100%",
          }}
          noValidate
          autoComplete="off"
        >
          <TextField
            fullWidth
            sx={{ marginBottom: "15px" }}
            defaultValue={tokenDemo}
            label={"Token Demo"}
            type="text"
            onChange={(e) => setTokenDemo(e.target.value.trim())}
            variant="filled"
          />
          <TextField
            fullWidth
            sx={{ marginBottom: "15px" }}
            defaultValue={tokenReal}
            type={"text"}
            label={"Token Real"}
            onChange={(e) => settokenReal(e.target.value.trim())}
            variant="filled"
          />
        </Box>

        <Button
          onClick={() =>
            handleSaveOptions({
              tokenDemo,
              tokenReal,
              key: "binarydata",
            })
          }
          variant="contained"
        >
         <Translator path="buttonSalvar"/>
        </Button>
      </CardContainer>

      <CardContainer>
        <SubTitle>Bitness</SubTitle>
        <Box
          component="form"
          sx={{
            maxWidth: "100%",
          }}
          noValidate
          autoComplete="off"
        >
          <TextField
            fullWidth
            sx={{ marginBottom: "15px" }}
            defaultValue={emailBitness}
            label={"Email"}
            type="email"
            onChange={(e) => setEmailBitness(e.target.value.trim())}
            variant="filled"
          />
          <TextField
            fullWidth
            sx={{ marginBottom: "15px" }}
            defaultValue={passBitness}
            type={"password"}
            label={t("senha")}
            onChange={(e) => setPassBitness(e.target.value.trim())}
            variant="filled"
          />
        </Box>

        <Button
          onClick={() =>
            handleSaveOptions({
              email: emailBitness,
              password: passBitness,
              key: "bitnessdata",
            })
          }
          variant="contained"
        >
          <Translator path="buttonSalvar"/>
        </Button>
      </CardContainer>

    </SubContainer>
  );
}
