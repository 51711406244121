import styled from "styled-components";

export const Container = styled.div``;

export const GridFollowContainer = styled.div`
  display: grid;

  @media (max-width: 765px) {
    grid-template-columns: 260px;
  }
  @media (min-width: 766px) {
    grid-template-columns: 260px 260px;
  }
  @media (min-width: 1026px) {
    grid-template-columns: 260px 260px 260px;
  }
  @media (min-width: 1286px) {
    grid-template-columns: 260px 260px 260px 260px;
  }
`;
export const GridTraderContainer = styled.div`
  display: grid;

  @media (max-width: 560px) {
    grid-template-columns: 310px;
  }
  @media (min-width: 870px) {
    grid-template-columns: 310px 310px;
  }
  @media (min-width: 1180px) {
    grid-template-columns: 310px 310px 310px;
  }
  @media (min-width: 1490px) {
    grid-template-columns: 310px 310px 310px 310px;
  }
`;
export const Divider = styled.div`
  margin-top: 5px;
  margin-bottom: 15px;
  border-bottom: 1px solid gray;
`;
export const Title = styled.div`
  font-size: 30px;
  font-weight: bold;
  letter-spacing: 1.5px;
  margin-bottom: 20px;
`;
export const SubTitle = styled.div`
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 1.5px;
  margin-left: 8px;
`;
export const InputContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 0 5px 0;
  gap: 8px;

  input {
    border: 0.5px solid gray;
    height: 25px;
  }
`;
export const FollowerContainer = styled.div`
  padding: 8px;
  margin: 8px;
  width: 250px;
  border: 1px solid gray;
  border-radius: 8px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
export const TraderContainer = styled.div`
  padding: 8px;
  margin: 8px;
  width: 300px;
  border: 1px solid gray;
  border-radius: 8px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
export const Button = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 360px;
  padding: 4px 8px;
  border: 1px solid gray;
  border-radius: 4px;
  margin-bottom: 30px;

  cursor: pointer;

  a {
    text-decoration: none;
    color: black;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  gap: 10px;

  .removeBtn,
  .desableBtn {
    border: 1px solid gray;
    padding: 4px 8px;
    border-radius: 8px;

    letter-spacing: 1.5px;
    cursor: pointer;
  }
  .removeBtn {
    background-color: red;
  }
  .desableBtn {
    background-color: cyan;
  }
`;
export const TextName = styled.div`
  font-size: 20px;
  margin-bottom: 10px;
`;
export const TextContainer = styled.div``;
