import {React, useState } from "react";

import { Container, SubContainer, SubTitle, Divider } from "./styles";
import { Button, TextField } from "@mui/material";
import { Box } from "@mui/system";
import { useUser } from "../../contexts/UserContext";
import Snackbar from "../../components/Snackbar";

import Translator from "../../components/i18nComponents/Translator";
import { useTranslation } from "react-i18next";

export default function CopyTraderSinais() {

    const { handleSaveTraderID } = useUser();
    const [traderid, setTraderID] = useState(null);
    const [saveStateOpen, setSaveStateOpen] = useState(false);
    const userid = localStorage.getItem("userId");
    const botIDs = JSON.parse(localStorage.getItem("botIDs"));
    //console.log('botIDs copytrader = ', botIDs);
    const {t} = useTranslation()

    const handleSave = async () => {
        if (traderid) {
            //
            //console.log('traderid = ', traderid);
            setSaveStateOpen((prevState) => !prevState);
            var val = userid;
            var index = botIDs.findIndex(function(item, i){
              return item.userid === val
            });
            var botIDNew = botIDs;
            botIDNew[index].traderid = traderid;
            //console.log('index = ', index);
            //console.log('botIDNew copytrader = ', botIDNew);
            localStorage.setItem("traderId", traderid);
            localStorage.setItem("botIDs", JSON.stringify(botIDNew));
            //
            handleSaveTraderID(userid, traderid);
            //
            window.location.assign("/copysignals");
        }
    }
  return (
    <Container>

      <SubTitle><Translator path="paginas.CopyTraderSignals.subTitle"/></SubTitle>
      <Divider />
      <br />

      <SubContainer>
        <div><Translator path="paginas.CopyTraderSignals.textIdTrader"/></div>
        <Box
          component="form"
          sx={{
            maxWidth: "100%",
          }}
          noValidate
          autoComplete="off"
        >
          <TextField
            fullWidth
            sx={{ marginBottom: "15px" }}
            value={traderid}
            type="number"
            onChange={(e) => {
                setTraderID(e.target.value);
            }}
            variant="filled"
          />
        </Box>
      </SubContainer>

      <Snackbar
        type="success"
        open={saveStateOpen}
        onClose={() => setSaveStateOpen(false)}
      ><Translator path="snackbarSucess"/>
      </Snackbar>

      <Button
        sx={{ width: "270px" }}
        onClick={() => handleSave()}
        variant="contained"
      ><Translator path="buttonSalvar"/></Button>
    </Container>
  );
}
