// eslint-disable-next-line import/no-anonymous-default-export
export default {
  translations: {
    // Mesmo valor usado nas configurações (defaultNS)

    // Valores universais
    buttonSalvar: "Guardar",
    snackbarSucess: "Guardado",
    buttonEnviar: "Enviar",
    senha: "Contraseña",

    // Componentes
    componentes: {

      MiniDrawer: {
        textUser: "Usuario",
        textRobo: "Robot",

        linksConfig: "Configuraciones",
        linksComprarRobo: "Comprar Robot",

      },

      // Alert Componente
      AlertsComponente: {
        //exemplo:"texto de ejemplo"
      },
      // Fim Alert Componente

      // ChannelCardComponente
      ChannelCardComponente: {
        // Textos sueltos en el componente
        TextoSalvo: "Guardado",
        ButtonRemover: "Eliminar",
        textCheckox:"Conectado",

        // Texto del Alert al final del else
        handleSaveElse: "Solo el trader puede eliminar salas",

        // Textos de etiqueta
        TextFieldMultiplicador: "Multiplicador",
        TextFieldEntrada: "Entrada:",
        TextFieldFiltro: "Filtro:",
        TextFieldIgnorar: "Ignorar:",

        // Textos del select SinalTipo
        SelectSinalTipo: {
          // Etiqueta
          labelSinalTipo: "Tipo de señal",
          // Elementos
          MenuItens: {
            MenuItem1: "Programar",
            MenuItem2: "Listas",
            MenuItem3: "Inmediato",
            MenuItem4: "Inmediato anterior",
            MenuItem5: "Inmediato posterior",
            MenuItem6: "Próxima vela inmediata",
            MenuItem7: "Final de la vela",
            MenuItem8: "Minuto corriendo",
          },
        },

        // Textos del select Corretora
        SelectCorretora: {
          // Etiqueta
          labelCorretora: "Corredora",
          // Elementos
          MenuItens: {
            MenuItem1: "Todas",
            MenuItem2: "IQ",
            MenuItem3: "Binary",
            MenuItem4: "DayProfit",
            MenuItem5: "CapitalBear",
            MenuItem6: "Quotex",
            MenuItem7: "Pocket",
            MenuItem8: "Mango",
            MenuItem9: "Exnova",
            MenuItem10: "Binomo",
            MenuItem11: "Bitness",
            MenuItem12: "Binolla",
            MenuItem13: "Olymp 1",
            MenuItem14: "Olymp 2",
          },
        },
      },
      // Fim ChannelCardComponente -----

      // ReaderParams
      ReaderParams: {
        textFusoHorario: "Zona horaria:",
        textOrdemSimultaneas: "Órdenes Simultáneas:",
        textFiltroHorarioInicio: "Filtro horario inicio:",
        textFiltroHorarioFinal: "Filtro horario fin:",
        
      },
      // Fim ReaderParams

      // GlobalParamsScreen
      globalParamsScreen:{
        formLabelConta:"Tipo de cuenta:",
        radioGroupConta:{
          ContaReal:"Cuenta real",
          ContaDemo:"Cuenta demo",
        },

        formLabelOpcao:"Tipo de opción",
        radioGroupOpcao:{
          Binarias:"Binarias",
          Digitais:"Digitales",
          Ambas:"Mayor Pago",
          BinDig:"Binaria/Digital"
        },

        formLabelEntrada:"Tipo de entrada:",
        radioGroupEntrada:{
          ValorFixo:"Valor fijo $",
          Percentual:"Porcentaje de cuenta",
          CopyPerc: "Copiar trader %"
        },

        textEntrada:"Entrada:",
        textGale: "Coeficiente",

        textWinStop:"Tipo de Ganancia/Stop:",
        MenuItemWinStop:{
          ValorFixo:"Valor fijo $",
          Percentual:"Porcentaje de cuenta",
          Diferencial1:"Diferencial loss",
          Diferencial2:"Diferencial win+loss",
          Movel:"Flotante",
        },
      },
      // Fim GlobalParamsScreen

      // LossRecoverScreen
      LossRecoverScreen:{
        textCiclos:"Ciclos:",
        textFatorCiclos:"Coenfiente do Ciclos:",
        textGaleCiclos: "Martingale:",
        textFatorGale:"Coenfiente do Gale",
        textBancaMinima:"Banca Mínima:",
        textNumeroHits:"Número de Éxitos",
        textPosLossEntrada:"PosLoss Entrada:",
        textPosLossFator:"Coenfiente do PosLoss:",
        textSoros:"Soros:",
        textSorosGale:"Soros Gale:",
        textSorosPercentual:"Soros Porcentaje:"
      }
      // Fim LossRecoverScreen

    },
    // Fim Componentes -------

    // Páginas
    paginas: {
      // Register
      register: {
        // Alert
        messageErrorDados: "Por favor, ingrese los datos correctamente!!",
        messageCadastroOk: "Registro exitoso!",
        messageEmailCadastrado: "Correo electrónico ya registrado",
        messageIdCadastrado: "ID ya registrado",
        messageErroCadastro: "Error en el registro",

        // Textos sueltos en la página
        titlePagina: "Pantalla de Registro",
        subtitlePagina: "Ingrese los datos de registro!!",

        // Etiquetas TextField
        textFields: {
          senha: "Contraseña:",
          confirmeSenha: "Confirme su contraseña:",
          userId: "ID de Telegram",
        },

        // Botón registrarse
        buttonCadastrar: "Registrarse",

        // Snackbar
        snackbarCadastroOk: "Registro exitoso!",

        // Textos de configuración
        config: {
          email: "El campo de correo electrónico no puede estar vacío.",
          password: "El campo de contraseña no puede estar vacío.",
          confirmPassword:
            "El campo de confirmación de contraseña es diferente al ingresado.",
          confirmPasswordNull:
            "El campo de confirmación de contraseña no puede estar vacío.",
          userId: "El campo de ID de Telegram no puede estar vacío.",
        },
      },
      // Fim register ------------

      // Reader config
      readerConfig: {
        titlePagina: "Parámetros del Lector",

        // El resto de la página está en el componente ReaderParams
      },
      // Fim Reader config ------------

      // ProxyConfig
      proxyConfig: {
        Title: "Parámetros de Proxy",
        textProxyendereco: "Dirección de Proxy:",
        textProxyPorta: "Puerto de Proxy:",
        textPassword: "Contraseña:",
      },
      // Fim ProxyConfig

      // NotFound (404)
      notFound: {
        Title: "Página no encontrada",
        buttonHome: "Volver a la página de inicio",
      },
      // Fim NotFound

      // MasanielloConfig
      masanielloConfig: {
        messageSaved:"Parámetros guardados",

        Title:"Gestión de Masaniello",

        textCapital:"Capital:",
        textTotalOperacoes:"Total de Operaciones:",
        textNumeroVitorias:"Número de Victorias:",
        textValorAlvo:"Valor Objetivo:",

        textTipo:"Tipo:",

        MenuItensTipo:{
          Desativado:"Desactivado",
          Normal:"Normal",
          Progressive:"Progresivo",
        },

        textModo:"Modo:",

        MenuItensModo:{
          UmSinal:"Una señal",
          MultiploSinais:"Múltiples señales"
        },

        CheckboxReiniciar:"Reiniciar ciclo",
      },
      // Fim MasanielloConfig

      // GeralConfig
      geralConfig:{
        titleGerais:"Parámetros Generales",
        titleRecuperacao:"Parámetros de Recuperación",

        // El resto de la página está en el componente GlobalParamsScreen
        // El resto de la página está en el componente LossRecoverScreen
      },
      // Fim GeralConfig

      addPhone:{
        Title:"Conectar a Telegram",

        textAddTelefone:"Agregar Teléfono:",
        textAddTelegram:"Código de Telegram:",
        textSenhaTelegram:"Contraseña de Telegram:",
      }, //Fim addPhone

      // AuthCode
      authCode:{
        Subtitle:"Ingrese correo electrónico y contraseña",

        labelSenha:"Contraseña",

        linkRegistro:"Regístrese aquí",
      }, //Fim authCode

      // BrokerConfig
      BrokerConfig:{
        Title:"Corredoras"


      }, //Fim BrokerConfig

      // BuyRobot
      buyRobot:{

        textTipoDePlano:"Tipo de plan",
        // días
        dias:{
          dias7:"7 días = R$25",
          dias15:"15 días = R$37",
          dias30:"30 días = R$67",
          dias90:"90 días = R$167",
          dias180:"180 días = R$300",
        },

        Title:"Pago",

        textMetodo:"Método",
        metodoItens:{
          MercadoPago:"Mercado Pago",
          Hotmart:"Hotmart",
          Pix:"Pix",
        },

        buttonGerarLink:"Generar Enlace",

        linkPagamento:"Enlace de Pago",

        // Etiqueta
        labelIdTelegram:"Su ID de Telegram:",
        labelCPF:"CPF (sin puntos ni guiones):",
        labelVendedor:"ID del Vendedor:",

        // Alerta
        messageDadosIncorretos:"Por favor, ingrese los datos correctamente!",
        messageCpf:"Por favor, ingrese el CPF",

      }, //Fim BuyRobot

      // ChatList
      channelList:{
        messageAtualizando:"Actualizando... espere unos segundos",
        messageApenasTrader:"Solo el trader puede actualizar/guardar",

        title:"Grupos y Canales",

        textAguarde:"Espere unos segundos!",
        buttonListar:"Listar"
      },
      // Fim ChatList

      // Configure
      configure:{
        Title:"Configuración del Robot",

        linkCorretora:"Corredoras",
        linkGeral:"Configuración General",
        linkCopySignals:"Copiar Señales",
        linkCopyTrade:"Copy Trader",
        linkMasaniello:"Masaniello",
        linkProxy:"Configurar Proxy",
        linkComprarRobo:"Comprar el Robot",
        linkSuporte:"Suporte sonnybot"
      },
      // Fim Configure

      // ConfigureChannels
      configureChannels:{
        Title:"Configurar Salas",
      },
      // Fim configureChannels

      // CopySignals
      copySignals:{
        Title:"Configuración de Copiar Señales",

        linkAddPhone:"Agregar Teléfono",
        linkChannelList:"Agregar Salas",
        linkConfigSala:"Configurar Salas",
        linkParametros:"Parámetros del Lector",
        linkCopyTrader:"Copiar Salas",

        buttonReset:"Reiniciar",
        buttonDeletar:"Eliminar",
      },
      // Fim CopySignals

      // CopyTrader
      copyTrader:{
        TitleMain: "Configuración Copytrader",
        TitleTrader:"Configuración Trader",
        TitleSeguidor:"Configuración Seguidor",

        textHabilitar:"Habilitar Copiar Trader",

        textListaSeguidores:"Lista de Seguidores",

        textSeguidor:"Seguidor",

        buttonRemover:"Eliminar",
        buttonDesativar:"Desactivar",

        textOrdemSimultaneas: "Ordens Simultâneas:",
        textMinValue: "Entrada Minima:",
        textMaxValue: "Entrada Máxima:",
      },
      // Fim CopyTrader

      // CopyTraderSignals
      CopyTraderSignals:{
        subTitle:"Configuración Copiar Trader",

        textIdTrader:"ID del Trader:",
      }

      // Fim CopyTraderSignals
    },
    // Fim Páginas
  },
};
