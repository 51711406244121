import { options } from "./config";

const BASE_URL = process.env.REACT_APP_BASE_URL

export const register = async (request) => {
    
    const body = options(request)
    
    return fetch(`${BASE_URL}/botregister`, body)
   
};