/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Box } from "@mui/system";
import { Button, TextField, MenuItem, Select, Checkbox } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import { useUser } from "../../contexts/UserContext";
import { Container, Title, Divider, SubContainer, InputContainer } from "./styles";
import { SubTitle } from "../../pages/CopyTrader/styles";

import Translator from "../../components/i18nComponents/Translator";
import {useTranslation} from 'react-i18next'

export default function MasanielloConfig() {

  const {t} = useTranslation()

  const [capital, setCapital] = useState(null);
  const [totalOper, setTotalOper] = useState(null);
  const [totalWin, setTotalWin] = useState(null);
  const [payout, setPayout] = useState(null);
  const [typeMas, setTypeMas] = useState(null);
  const [restart, setRestart] = useState(null);
  const [gale, setGale] = useState(null);
  const [target, setTarget] = useState(null);
  const [modoMasa, setModoMasa] = useState(null);

  const { sendMasaParams, handleMasanielloParams, masaParams, userId } = useUser();

  const handleSaveMasa = async (capital, totalOper, totalWin, payout, gale, typeMas, restart, target, modoMasa) => {

    if (capital == 0 || totalOper == 0 || totalWin == 0 || payout == 0) {
        alert("Parametros princiapis não podem ser 0");
        return;
    } if (payout < 1 || payout > 99) {
        alert("Payout invalido. Use valores entre 1 e 99");
        return;
    } else {
      const response = await sendMasaParams(capital, totalOper, totalWin, payout, gale, typeMas, restart, target, modoMasa);
    
        if (response)
          alert(t("paginas.masanielloConfig.messageSaved"))
        else {
          alert("Parâmetros inválidos");
          console.log(response);
        }
    };

  };

  const handleSetMasaParams = (data) => {
    setCapital(data.capital);
    setTotalOper(data.totalOper);
    setTotalWin(data.totalWin);
    setPayout(data.payout);
    setTypeMas(data.typeMas);
    setRestart(data.restart);
    setGale(data.gale);
    setTarget(data.target);
    setModoMasa(data.modoMasa);
  };

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    if (masaParams) handleSetMasaParams(masaParams);
  }, [masaParams]);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    if (userId) handleMasanielloParams();
  }, [userId]);

  return (
    <Container>
      <Title><Translator path="paginas.masanielloConfig.Title"/></Title>
      <Divider />

      <SubContainer>
        <div><Translator path="paginas.masanielloConfig.textCapital"/></div>
        <Box
          component="form"
          sx={{
            maxWidth: "100%",
          }}
          noValidate
          autoComplete="off"
        >
          <TextField
            fullWidth
            sx={{ marginBottom: "15px" }}
            value={capital}
            type="number"
            onChange={(e) => {
              setCapital(e.target.value.trim());
            }}
            variant="filled"
          />
        </Box>
      </SubContainer>

      <SubContainer>
        <div><Translator path="paginas.masanielloConfig.textTotalOperacoes"/></div>
        <Box
          component="form"
          sx={{
            maxWidth: "100%",
          }}
          noValidate
          autoComplete="off"
        >
          <TextField
            fullWidth
            sx={{ marginBottom: "15px" }}
            value={totalOper}
            type="number"
            onChange={(e) => {
              setTotalOper(e.target.value.trim());
            }}
            variant="filled"
          />
        </Box>
      </SubContainer>

      <SubContainer>
        <div><Translator path="paginas.masanielloConfig.textNumeroVitorias"/></div>
        <Box
          component="form"
          sx={{
            maxWidth: "100%",
          }}
          noValidate
          autoComplete="off"
        >
          <TextField
            fullWidth
            sx={{ marginBottom: "15px" }}
            value={totalWin}
            type="number"
            onChange={(e) => {
              setTotalWin(e.target.value.trim());
            }}
            variant="filled"
          />
        </Box>
      </SubContainer>

      <SubContainer>
        <div>Payout:</div>
        <Box
          component="form"
          sx={{
            maxWidth: "100%",
          }}
          noValidate
          autoComplete="off"
        >
          <TextField
            fullWidth
            sx={{ marginBottom: "15px" }}
            value={payout}
            type="number"
            onChange={(e) => {
              setPayout(e.target.value.trim());
            }}
            variant="filled"
          />
        </Box>
      </SubContainer>

      <SubContainer>
        <div>Gale:</div>
        <Box
          component="form"
          sx={{
            maxWidth: "100%",
          }}
          noValidate
          autoComplete="off"
        >
          <TextField
            fullWidth
            sx={{ marginBottom: "15px" }}
            value={gale}
            type="number"
            onChange={(e) => {
              setGale(e.target.value.trim());
            }}
            variant="filled"
          />
        </Box>
      </SubContainer>

      <SubContainer>
        <div><Translator path="paginas.masanielloConfig.textValorAlvo"/></div>
        <Box
          component="form"
          sx={{
            maxWidth: "100%",
          }}
          noValidate
          autoComplete="off"
        >
          <TextField
            fullWidth
            sx={{ marginBottom: "15px" }}
            value={target}
            type="number"
            onChange={(e) => {
              setTarget(e.target.value.trim());
            }}
            variant="filled"
          />
        </Box>
      </SubContainer>

      <SubContainer>
        <FormControl sx={{ minWidth: "30%" }} size="small">
          <SubTitle><Translator path="paginas.masanielloConfig.textTipo"/></SubTitle>
          <Select
            sx={{ width: "275px" }}
            value={typeMas}
            defaultValue={0}
            onChange={(e) => setTypeMas(e.target.value)}
            required
          >
            <MenuItem value={0}><Translator path="paginas.masanielloConfig.MenuItensTipo.Desativado"/></MenuItem>
            <MenuItem value={1}><Translator path="paginas.masanielloConfig.MenuItensTipo.Normal"/></MenuItem>
            <MenuItem value={2}><Translator path="paginas.masanielloConfig.MenuItensTipo.Progressive"/></MenuItem>
          </Select>
        </FormControl>
      </SubContainer>

      <SubContainer>
        <FormControl sx={{ minWidth: "30%" }} size="small">
          <SubTitle><Translator path="paginas.masanielloConfig.textModo"/></SubTitle>
          <Select
            sx={{ width: "275px" }}
            value={modoMasa}
            defaultValue={0}
            onChange={(e) => setModoMasa(e.target.value)}
            required
          >
            <MenuItem value={1}><Translator path="paginas.masanielloConfig.MenuItensModo.UmSinal"/></MenuItem>
            <MenuItem value={1}><Translator path="paginas.masanielloConfig.MenuItensModo.UmSinal"/></MenuItem>
          </Select>
        </FormControl>
      </SubContainer>

      <InputContainer>
        <Checkbox
          checked={restart}
          name={"chanEnable"}
          id={"idEn"}
          onChange={() => setRestart(!restart)}
        />
        <div><Translator path="paginas.masanielloConfig.CheckboxReiniciar"/></div>
      </InputContainer>

      <Button
        sx={{ width: "270px" }}
        onClick={() => handleSaveMasa(capital, totalOper, totalWin, payout, gale, typeMas, restart, target, modoMasa)}
        variant="contained"
      ><Translator path="buttonSalvar"/></Button>

    </Container>
  );
}