import React, { useCallback, useEffect, useState } from "react";

import { Box } from "@mui/system";
import { Button, TextField } from "@mui/material";

import { SubContainer, Divider } from "./styles";
import Snackbar from "../../components/Snackbar";
import { useUser } from "../../contexts/UserContext";
import Translator from "../i18nComponents/Translator";

export default function LossRecoverScreen() {

  const { handleSaveRecoveryParams, handleRecoveryParams, recoveryParams, geralConfig, userId } =
    useUser();

  const [ciclos, setCiclos] = useState(null);
  const [ciclosFat, setCiclosFat] = useState(null);
  const [ciclosGale, setCiclosGale] = useState(null);
  const [ciclosGaleFat, setCiclosGaleFat] = useState(null);
  const [soros, setSoros] = useState(null);
  const [sorosGale, setSorosGale] = useState(null);
  const [banca, setBanca] = useState(null);
  const [sorosPerc, setSorosPerc] = useState(null);
  const [saveStateOpen, setSaveStateOpen] = useState(false);
  //
  const [numHits, setNumHits] = useState(null);
  const [posLote, setPosLote] = useState(null);
  const [posFat, setPosFat] = useState(null);

  const handleSaveParamsRecv = useCallback(async () => {
    //
    setSaveStateOpen((prevState) => !prevState);
    //
    //console.log('handleSaveParamsRecv');
    //
    await handleSaveRecoveryParams({
      ciclos,
      ciclosFat,
      ciclosGale,
      ciclosGaleFat,
      soros,
      sorosGale,
      sorosPerc,
      numHits,
      posLote,
      posFat,
    });
  }, [
    handleSaveRecoveryParams,
      ciclos,
      ciclosFat,
      ciclosGale,
      ciclosGaleFat,
      soros,
      sorosGale,
      sorosPerc,
      numHits,
      posLote,
      posFat,
  ]);

  const handleSetRecoveryParams = (data) => {
    setCiclos(data.ciclos);
    setCiclosFat(data.ciclosfat);
    setCiclosGale(data.ciclosgale);
    setCiclosGaleFat(data.ciclosgalefat);
    setSoros(data.soros);
    setSorosGale(data.sorosgale);
    setSorosPerc(data.sorosperc);
    setNumHits(data.numhits);
    setPosLote(data.poslosslote);
    setPosFat(data.poslossfat);
  };

  useEffect(() => {
    if (recoveryParams)  {
      handleSetRecoveryParams(recoveryParams);
    }
  }, [recoveryParams]);

  useEffect(() => {
    if (userId)
      handleRecoveryParams();
  }, [userId]);

  useEffect(() => {
    if (geralConfig.loteGale) {
      //console.log('ciclosGale', recoveryParams.ciclosgale);
      if (recoveryParams.ciclosgale) {
        //
        var lote1 = geralConfig.loteGale * recoveryParams.ciclosfat;
        var fator = 1;
        if (recoveryParams.ciclosgalefat === 0) {
          fator = recoveryParams.ciclosfat;
        } else {
          fator = recoveryParams.ciclosgalefat;
        }
        pgSum = (lote1 * (Math.pow(fator, recoveryParams.ciclos+
                          recoveryParams.ciclosgale+geralConfig.galenum+1) - 1)) / (fator - 1);
        setBanca(pgSum.toFixed(2));
      } else {
        var pgSum = (geralConfig.loteGale * (Math.pow(recoveryParams.ciclosfat, recoveryParams.ciclos+1) - 1)) / (recoveryParams.ciclosfat - 1);
        pgSum += geralConfig.loteConf;
        setBanca(pgSum.toFixed(2));
      }
    }
  }, [geralConfig]);

  return (
    <>

      <SubContainer>
        <div><Translator path="componentes.LossRecoverScreen.textCiclos"/></div>
        <Box
          component="form"
          sx={{
            maxWidth: "100%",
          }}
          noValidate
          autoComplete="off"
        >
          <TextField
            fullWidth
            sx={{ marginBottom: "15px" }}
            value={ciclos}
            type="number"
            onChange={(e) => {
              setCiclos(e.target.value);
            }}
            variant="filled"
          />
        </Box>
      </SubContainer>

      <SubContainer>
        <div><Translator path="componentes.LossRecoverScreen.textFatorCiclos"/></div>
        <Box
          component="form"
          sx={{
            maxWidth: "100%",
          }}
          noValidate
          autoComplete="off"
        >
          <TextField
            fullWidth
            sx={{ marginBottom: "15px" }}
            value={ciclosFat}
            type="number"
            onChange={(e) => {
              setCiclosFat(e.target.value);
            }}
            variant="filled"
          />
        </Box>
      </SubContainer>

      <SubContainer>
      <div><Translator path="componentes.LossRecoverScreen.textGaleCiclos"/></div>
        <Box
          component="form"
          sx={{
            maxWidth: "100%",
          }}
          noValidate
          autoComplete="off"
        >
          <TextField
            fullWidth
            sx={{ marginBottom: "15px" }}
            value={ciclosGale}
            type="number"
            onChange={(e) => {
              setCiclosGale(e.target.value);
            }}
            variant="filled"
          />
        </Box>
      </SubContainer>

      <SubContainer>
        <div><Translator path="componentes.LossRecoverScreen.textFatorGale"/></div>
        <Box
          component="form"
          sx={{
            maxWidth: "100%",
          }}
          noValidate
          autoComplete="off"
        >
          <TextField
            fullWidth
            sx={{ marginBottom: "15px" }}
            value={ciclosGaleFat}
            type="number"
            onChange={(e) => {
              setCiclosGaleFat(e.target.value);
            }}
            variant="filled"
          />
        </Box>
      </SubContainer>
      <Divider />

      <SubContainer>
        <div><Translator path="componentes.LossRecoverScreen.textSoros"/></div>
        <Box
          component="form"
          sx={{
            maxWidth: "100%",
          }}
          noValidate
          autoComplete="off"
        >
          <TextField
            fullWidth
            sx={{ marginBottom: "15px" }}
            value={soros}
            type="number"
            onChange={(e) => {
              setSoros(e.target.value);
            }}
            variant="filled"
          />
        </Box>
      </SubContainer>

      <SubContainer>
        <div><Translator path="componentes.LossRecoverScreen.textSorosGale"/></div>
        <Box
          component="form"
          sx={{
            maxWidth: "100%",
          }}
          noValidate
          autoComplete="off"
        >
          <TextField
            fullWidth
            sx={{ marginBottom: "15px" }}
            value={sorosGale}
            type="number"
            onChange={(e) => {
              setSorosGale(e.target.value);
            }}
            variant="filled"
          />
        </Box>
      </SubContainer>

      <SubContainer>
        <div><Translator path="componentes.LossRecoverScreen.textSorosPercentual"/></div>
        <Box
          component="form"
          sx={{
            maxWidth: "100%",
          }}
          noValidate
          autoComplete="off"
        >
          <TextField
            fullWidth
            sx={{ marginBottom: "15px" }}
            value={sorosPerc}
            type="number"
            onChange={(e) => {
              setSorosPerc(e.target.value);
            }}
            variant="filled"
          />
        </Box>
      </SubContainer>

      <Snackbar
        type="success"
        open={saveStateOpen}
        autoHideDuration={2000}
        onClose={() => setSaveStateOpen(false)}
      >
        <Translator path="snackbarSucess"/>
      </Snackbar>

      <Button
        sx={{ width: "270px" }}
        onClick={() => handleSaveParamsRecv()}
        variant="contained"
      ><Translator path="buttonSalvar"/></Button>

    </>
  );
}
