import styled from "styled-components";

export const Container = styled.div``;

export const Divider = styled.div`
  margin-top: 5px;
  margin-bottom: 15px;
  border-bottom: 1px solid gray;
`;
export const Title = styled.div`
  font-size: 30px;
  font-weight: bold;
  letter-spacing: 1.5px;
  margin-bottom: 20px;
`;
export const Button = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 260px;
  padding: 4px 6px;
  border: 1px solid gray;
  border-radius: 4px;

  margin: 8px 0;

  cursor: pointer;

  a {
    text-decoration: none;
    color: white;
  }
`;
