import React, { useEffect } from "react";

import { ChannelContainer, Container, Divider, Title } from "./styles";
import ChannelCard from "../../components/ChannelCard";
import { useUser } from "../../contexts/UserContext";

import Translator from "../../components/i18nComponents/Translator";

export default function Channel() {
  const { listGroups, handleGetGroups } = useUser();

  useEffect(() => {
    handleGetGroups();
  }, []);

  return (
    <Container>
      <Title><Translator path="paginas.configureChannels.Title"/></Title>
      <Divider />

      <ChannelContainer>
        {listGroups.map((channel, index) => (
          <ChannelCard channel={channel} key={index} />
        ))}
      </ChannelContainer>
    </Container>
  );
}
