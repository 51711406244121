import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import "./flags.css"
import { brazilFlag, euaFlag, esFlag } from '../../assets'
import Flag from './Flag'

const SwitchFlags = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { i18n } = useTranslation()
  const [selecionado, setSelecionado]= useState(brazilFlag)

  useEffect(()=>{
    handleChangeLanguage(i18n.language)
  },[])

  function handleChangeLanguage(language) {
    if(language === 'pt-BR'){
      setSelecionado(brazilFlag)
    }else if(language === "en-US"){
      setSelecionado(euaFlag)
    }else{
      setSelecionado(esFlag)
    }
    i18n.changeLanguage(language)
  }

  const selectedLanguage = i18n.language 
  return (
    
    <div className="dropdown">
      <img src={selecionado} className='SelectFlag' alt="Bandeira Selecionada" />
    <div className="flags-container">
      {/* // Bandeira do Brasil */}
      <Flag
      className="flagIdioma"
        image={brazilFlag}
        isSelected={selectedLanguage === 'pt-BR'} // Verifica o idioma escolhido
        onClick={() => handleChangeLanguage('pt-BR')} // Troca o idioma para pt-BR
      />
      {/* // Bandeira dos EUA */}
      <Flag
      className="flagIdioma"
        image={euaFlag}
        isSelected={selectedLanguage === 'en-US'} 
        onClick={() => handleChangeLanguage('en-US')} 
        
      />
      {/* // Bandeira da Espanha */}
      <Flag
      className="flagIdioma"
        image={esFlag}
        isSelected={selectedLanguage === 'es-ES'} 
        onClick={() => handleChangeLanguage('es-ES')} 
        
      />
    </div>
    </div>


  )
}

export default SwitchFlags
