import React from "react";
import { Button, Container, Divider, Title } from "./styles";
import { Link } from 'react-router-dom';

import Translator from "../../components/i18nComponents/Translator";

export default function Configure() {
  return (
    <Container>
      <Title><Translator path="paginas.configure.Title"/></Title>
      <Divider />

      <Button>
        <a href="/brokerconfig"><Translator path="paginas.configure.linkCorretora"/></a>
      </Button>

      <Button>
        <a href="/geralconfig"><Translator path="paginas.configure.linkGeral"/></a>
      </Button>

      <Button>
        <a href="/copysignals"><Translator path="paginas.configure.linkCopySignals"/></a>
      </Button>

      <Button>
        <a href="/copytrader"><Translator path="paginas.configure.linkCopyTrade"/></a>
      </Button>

      <Button>
        <a href="/config-masaniello"><Translator path="paginas.configure.linkMasaniello"/></a>
      </Button>

      <Button>
        <a href="/configproxy"><Translator path="paginas.configure.linkProxy"/></a>
      </Button>

      <Button>
        <a href="/comprar"><Translator path="paginas.configure.linkComprarRobo"/></a>
      </Button>

      <Button
          type="button"
          onClick={(e) => {
            e.preventDefault();
            window.location.href='https://t.me/suporte_sonnybot';
            }}
      > <Translator path="paginas.configure.linkSuporte"/></Button>
    </Container>
  );
}
