import React, { useCallback, useEffect, useState } from "react";

import { Box } from "@mui/system";
import Radio from "@mui/material/Radio";
import FormLabel from "@mui/material/FormLabel";
import RadioGroup from "@mui/material/RadioGroup";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Button, MenuItem, Select, TextField } from "@mui/material";

import { Container, SubContainer, Divider } from "./styles";
import Snackbar from "../../components/Snackbar";
import { useUser } from "../../contexts/UserContext";
import { SubTitle } from "../../pages/CopyTrader/styles";
import Translator from "../i18nComponents/Translator";
import { useTranslation } from "react-i18next";

var loteGale;
export {loteGale};

export default function GlobalParamsScreen() {

  const {t} = useTranslation()

  const { handleGlobalParams, handleSaveParameters, userId, geralConfig } =
    useUser();

  const [traderMode, setTraderMode] = useState(null);
  const [accType, setAccType] = useState(null);
  const [optionType, setOptionType] = useState(null);
  const [loteType, setLoteType] = useState(null);
  const [stopType, setStopType] = useState(null);
  const [lote, setLote] = useState(null);
  const [galenum, setGalenum] = useState(null);
  const [galefat, setGalefat] = useState(null);
  const [stopWin, setStopWin] = useState(null);
  const [stopLoss, setStopLoss] = useState(null);
  const [payout, setPayout] = useState(null);
  const [brokerID, setBrokerID] = useState(null);
  const [someStateOpen, setSomeStateOpen] = useState(false);

  const handleSaveParameter = useCallback(async () => {
    //
    setSomeStateOpen((prevState) => !prevState);
    //
    await handleSaveParameters({
      accType,
      optionType,
      loteType,
      lote,
      galenum,
      galefat,
      stopWin,
      stopLoss,
      payout,
      stopType,
      brokerID,
      traderMode,
    });
  }, [
    handleSaveParameters,
    accType,
    optionType,
    loteType,
    lote,
    galenum,
    galefat,
    stopWin,
    stopLoss,
    payout,
    stopType,
    brokerID,
    traderMode,
  ]);

  const handleSetInformation = (data) => {
    setTraderMode(data.traderMode);
    setAccType(data.accType);
    setOptionType(data.optionType);
    setLoteType(data.loteType);
    setStopType(data.stopType);
    setLote(data.lote);
    setGalenum(data.galenum);
    setGalefat(data.galefat);
    setStopWin(data.stopWin);
    setStopLoss(data.stopLoss);
    setPayout(data.payout);
    setBrokerID(data.brokerID);
  };

  useEffect(() => {
    if (geralConfig) {

        handleSetInformation(geralConfig);
        //
    }
  }, [geralConfig]);

  useEffect(() => {
    if (userId) handleGlobalParams();
  }, [userId]);

  const handleBrokerID = (event) => {
    setBrokerID(event.target.value);
  };

  return (
    <>

      <Container>
        <FormControl sx={{ minWidth: "30%" }} size="small">
          <SubTitle>Tipo de Conta:</SubTitle>
          <Select
            sx={{ width: "275px" }}
            value={accType}
            onChange={(e) => setAccType(e.target.value)}
            required
          >
          <MenuItem value={1}>Demo</MenuItem>
          <MenuItem value={2}>Real</MenuItem>
          <MenuItem value={3}>Torneio</MenuItem>
          </Select>
        </FormControl>
      </Container>

      <Container>
        <FormControl sx={{ minWidth: "30%" }} size="small">
          <SubTitle>Tipo de opção (IQ):</SubTitle>
          <Select
            sx={{ width: "275px" }}
            value={optionType}
            onChange={(e) => setOptionType(e.target.value)}
            required
          >
          <MenuItem value={1}>Binarias</MenuItem>
          <MenuItem value={2}>Digitais</MenuItem>
          <MenuItem value={3}>Maior payout</MenuItem>
          <MenuItem value={4}>Binaria/Digital</MenuItem>
          </Select>
        </FormControl>
      </Container>

      <Container>
        <FormControl>
          <FormLabel id="demo-controlled-radio-buttons-group">
            Modo de operação (CopyTrader):
          </FormLabel>
          <RadioGroup
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            value={traderMode}
            onChange={(event) => setTraderMode(event.target.value)}
          >
            <SubContainer>
              <FormControlLabel
                value="1"
                control={<Radio />}
                label="Modo trader"
              />
              <FormControlLabel
                value="2"
                control={<Radio />}
                label="Modo seguidor"
              />
            </SubContainer>
          </RadioGroup>
        </FormControl>
      </Container>

      <Divider />

     <Container>
        <FormControl sx={{ minWidth: "30%" }} size="small">
          <SubTitle>Seleciona corretora:</SubTitle>
          <Select
            sx={{ width: "275px" }}
            value={brokerID}
            onChange={(e) => setBrokerID(e.target.value)}
            required
          >
          <MenuItem value={1}>IQ</MenuItem>
          <MenuItem value={2}>Binary</MenuItem>
          <MenuItem value={3}>DayProfit</MenuItem>
          <MenuItem value={4}>CapitalBear</MenuItem>
          <MenuItem value={5}>Quotex</MenuItem>
          <MenuItem value={6}>Pocket</MenuItem>
          <MenuItem value={7}>Mango</MenuItem>
          <MenuItem value={8}>Exnova</MenuItem>
          <MenuItem value={9}>Binomo</MenuItem>
          <MenuItem value={10}>Bitness</MenuItem>
          <MenuItem value={11}>Binolla</MenuItem>
          <MenuItem value={12}>Olymp 1</MenuItem>
          <MenuItem value={13}>Olymp 2</MenuItem>
          </Select>
        </FormControl>
      </Container>
      <Divider />

      <Container>
        <FormControl sx={{ minWidth: "30%" }} size="small">
          <SubTitle><Translator path="componentes.globalParamsScreen.formLabelEntrada"/></SubTitle>
          <Select
            sx={{ width: "275px" }}
            value={loteType}
            onChange={(e) => setLoteType(e.target.value)}
            required
          >
            <MenuItem value={1}><Translator path="componentes.globalParamsScreen.radioGroupEntrada.ValorFixo"/></MenuItem>
            <MenuItem value={2}><Translator path="componentes.globalParamsScreen.radioGroupEntrada.Percentual"/></MenuItem>
            <MenuItem value={3}><Translator path="componentes.globalParamsScreen.radioGroupEntrada.CopyPerc"/></MenuItem>
          </Select>
        </FormControl>
      </Container>

      <SubContainer>
        <div><Translator path="componentes.globalParamsScreen.textEntrada"/></div>
        <Box
          component="form"
          sx={{
            maxWidth: "100%",
          }}
          noValidate
          autoComplete="off"
        >
          <TextField
            fullWidth
            sx={{ marginBottom: "15px" }}
            value={lote}
            type="number"
            onChange={(e) => {
              setLote(e.target.value);
            }}
            variant="filled"
          />
        </Box>
      </SubContainer>

      <SubContainer>
        <div>Martingale:</div>
        <Box
          component="form"
          sx={{
            maxWidth: "100%",
          }}
          noValidate
          autoComplete="off"
        >
          <TextField
            fullWidth
            sx={{ marginBottom: "15px" }}
            value={galenum}
            type="number"
            onChange={(e) => {
              setGalenum(e.target.value);
            }}
            variant="filled"
          />
        </Box>
      </SubContainer>

      <SubContainer>
      <div><Translator path="componentes.globalParamsScreen.textGale"/></div>
        <Box
          component="form"
          sx={{
            maxWidth: "100%",
          }}
          noValidate
          autoComplete="off"
        >
          <TextField
            fullWidth
            sx={{ marginBottom: "15px" }}
            value={galefat}
            type="number"
            onChange={(e) => {
              setGalefat(e.target.value);
            }}
            variant="filled"
          />
        </Box>
      </SubContainer>

      <SubContainer>
        <div>Payout:</div>
        <Box
          component="form"
          sx={{
            maxWidth: "100%",
          }}
          noValidate
          autoComplete="off"
        >
          <TextField
            fullWidth
            sx={{ marginBottom: "15px" }}
            value={payout}
            type="number"
            onChange={(e) => {
              setPayout(e.target.value);
            }}
            variant="filled"
          />
        </Box>
      </SubContainer>

      <Container>
        <FormControl sx={{ minWidth: "30%" }} size="small">
          <SubTitle><Translator path="componentes.globalParamsScreen.textWinStop"/></SubTitle>
          <Select
            sx={{ width: "275px" }}
            value={stopType}
            onChange={(e) => setStopType(e.target.value)}
            required
          >
            <MenuItem value={1}><Translator path="componentes.globalParamsScreen.MenuItemWinStop.ValorFixo"/></MenuItem>
            <MenuItem value={2}><Translator path="componentes.globalParamsScreen.MenuItemWinStop.Percentual"/></MenuItem>
            <MenuItem value={3}><Translator path="componentes.globalParamsScreen.MenuItemWinStop.Diferencial1"/></MenuItem>
            <MenuItem value={4}><Translator path="componentes.globalParamsScreen.MenuItemWinStop.Diferencial2"/></MenuItem>
          </Select>
        </FormControl>
      </Container>

      <SubContainer>
        <div>Stop win:</div>
        <Box
          component="form"
          sx={{
            maxWidth: "100%",
          }}
          noValidate
          autoComplete="off"
        >
          <TextField
            fullWidth
            sx={{ marginBottom: "15px" }}
            value={stopWin}
            type="number"
            onChange={(e) => {
              setStopWin(e.target.value);
            }}
            variant="filled"
          />
        </Box>
      </SubContainer>

      <SubContainer>
        <div>Stop loss:</div>
        <Box
          component="form"
          sx={{
            maxWidth: "100%",
          }}
          noValidate
          autoComplete="off"
        >
          <TextField
            fullWidth
            sx={{ marginBottom: "15px" }}
            value={stopLoss}
            type="number"
            onChange={(e) => {
              setStopLoss(e.target.value);
            }}
            variant="filled"
          />
        </Box>
      </SubContainer>

      <Snackbar
        type="success"
        open={someStateOpen}
        autoHideDuration={2000}
        onClose={() => setSomeStateOpen(false)}
      >
        <Translator path="snackbarSucess"/>
      </Snackbar>

      <Button
        sx={{ width: "270px" }}
        onClick={() => handleSaveParameter()}
        variant="contained"
      ><Translator path="buttonSalvar"/></Button>
    </>
  );
}
