// eslint-disable-next-line import/no-anonymous-default-export
export default {
  translations: {
    // Mesmo valor usado nas configurações (defaultNS)

    // Valores universais
    buttonSalvar: "Salvar",
    snackbarSucess: "Salvo",
    buttonEnviar:"Enviar",
    senha:"Senha",

    // Componentes
    componentes: {

      MiniDrawer:{
        textUser:"Usuario",
        textRobo:"Robo",

        linksConfig:"Configurações",
        linksComprarRobo:"Comprar Robo",

      },

      // Alert Componente
      AlertsComponente: {
        //exemplo:"texto de exemplo"
      },
      // Fim Alert Componente

      // ChannelCardComponente
      ChannelCardComponente: {
        // Textos soltos no componente
        TextoSalvo: "Salvo",
        ButtonRemover: "Remover",
        textCheckox:"Habilitado",

        // Texto do Alert no fim do else
        handleSaveElse: "Apenas trader pode remover salas",

        // Textos de label
        TextFieldMultiplicador: "Multiplicador",
        TextFieldEntrada: "Entrada:",
        TextFieldFiltro: "Filtro:",
        TextFieldIgnorar: "Ignorar:",

        // Textos do select SinalTipo
        SelectSinalTipo: {
          // Label
          labelSinalTipo: "sinalTipo",
          // Itens
          MenuItens: {
            MenuItem1: "Agendar",
            MenuItem2: "Listas",
            MenuItem3: "Imediato",
            MenuItem4: "Imediato anterior",
            MenuItem5: "Imediato posterior",
            MenuItem6: "Imediato próxima vela",
            MenuItem7: "Fim da vela",
            MenuItem8: "Minuto corrido",
          },
        },

        // Textos do select Corretora
        SelectCorretora: {
          // Label
          labelCorretora: "Corretora",
          // Itens
          MenuItens: {
            MenuItem1: "Todas",
            MenuItem2: "IQ",
            MenuItem3: "Binary",
            MenuItem4: "DayProfit",
            MenuItem5: "CapitalBear",
            MenuItem6: "Quotex",
            MenuItem7: "Pocket",
            MenuItem8: "Mango",
            MenuItem9: "Exnova",
            MenuItem10: "Binomo",
            MenuItem11: "Bitness",
            MenuItem12: "Binolla",
            MenuItem13: "Olymp 1",
            MenuItem14: "Olymp 2",
          },
        },
      },
      // Fim ChannelCardComponente -----

      // ReaderParams
      ReaderParams: {
        textFusoHorario: "Fuso horário:",
        textOrdemSimultaneas: "Ordens Simultâneas:",
        textFiltroHorarioInicio: "Filtro horário inicio:",
        textFiltroHorarioFinal: "Filtro horário fim:",
        
      },
      // Fim ReaderParams

      // GlobalParamsScreen
      globalParamsScreen:{
        formLabelConta:"Tipo de conta:",
        radioGroupConta:{
          ContaReal:"Conta real",
          ContaDemo:"Conta demo",
        },

        formLabelOpcao:"Tipo de opção",
        radioGroupOpcao:{
          Binarias:"Binarias",
          Digitais:"Digitais",
          Ambas:"Maior Payout",
          BinDig:"Binaria/Digital"
        },

        formLabelEntrada:"Tipo de entrada:",
        radioGroupEntrada:{
          ValorFixo:"Valor fixo $",
          Percentual:"Percentual Banca",
          CopyPerc: "Copiar trader %"
        },

        textEntrada:"Entrada:",
        textGale: "Multiplicador",

        textWinStop:"Tipo de Win/Stop:",
        MenuItemWinStop:{
          ValorFixo:"Valor fixo $",
          Percentual:"Percentual Banca",
          Diferencial1:"Diferencial loss",
          Diferencial2:"Diferencial win+loss",
          Movel:"Móvel",
        },
      },
      // Fim GlobalParamsScreen

      // LossRecoverScreen
      LossRecoverScreen:{
        textCiclos:"Ciclos:",
        textFatorCiclos:"Multiplicador do Ciclos:",
        textGaleCiclos: "Martingale:",
        textFatorGale:"Multiplicador do Gale",
        textBancaMinima:"Banca Minima:",
        textNumeroHits:"Número de Hits",
        textPosLossEntrada:"PosLoss Entrada:",
        textPosLossFator:"PosLoss Multiplicador:",
        textSoros:"Soros:",
        textSorosGale:"Soros Gale:",
        textSorosPercentual:"Soros Percentual:"
      }
      // Fim LossRecoverScreen

    },
    // Fim Componentes -------

    // Páginas
    paginas: {
      // Register
      register: {
        // Alert
        messageErrorDados: "Favor insira os dados corretamente!!",
        messageCadastroOk: "Cadastro ok!",
        messageEmailCadastrado: "Email já cadastrado",
        messageIdCadastrado: "ID já cadastrado",
        messageErroCadastro: "Erro no cadastro",

        // Textos soltos na página
        titlePagina: "Tela de Cadastro",
        subtitlePagina: "Insira os dados de cadastro!!",

        // Label TextField
        textFields: {
          senha: "senha:",
          confirmeSenha: "Confime sua senha:",
          userId: "Id do Telegram",
        },

        // Botão cadastrar
        buttonCadastrar: "Cadastrar",

        // Snackbar
        snackbarCadastroOk: "Cadastro Ok!",

        // Textos da config
        config: {
          email: "O campo email não pode ficar vazio!",
          password: "O campo senha não pode ficar vazio!",
          confirmPassword:
            "O campo senha de confirmação é diferente da digitada",
          confirmPasswordNull:
            "O campo confirmação de senha não pode ficar vazio!",
          userId: "O campo Telegram ID não pode ficar vazio!",
        },
      },
      // Fim register ------------

      // Reader config
      readerConfig: {
        titlePagina: "Parâmetros do Leitor",

        // Restante da página no componente ReaderParams
      },
      // Fim Reader config ------------

      // ProxyConfig
      proxyConfig: {
        Title: "Parâmetros Proxy",
        textProxyendereco: "Proxy endereço:",
        textProxyPorta: "Proxy Porta:",
        textPassword: "Senha:",
      },
      // Fim ProxyConfig

      // NotFound (404)
      notFound: {
        Title: "Pagina não encontrada",
        buttonHome: "Voltar a Home",
      },
      // Fim NotFound

      // MesanielloConfig
      masanielloConfig: {
        messageSaved:"Parametros salvos",

        Title:"Gerenciamento Masaniello",

        textCapital:"Capital:",
        textTotalOperacoes:"Total de Operações:",
        textNumeroVitorias:"Número de Vitórias:",
        textValorAlvo:"Valor Alvo:",

        textTipo:"Tipo:",

        MenuItensTipo:{
          Desativado:"Desativado",
          Normal:"Normal",
          Progressive:"Progressivo",
        },

        textModo:"Modo:",

        MenuItensModo:{
          UmSinal:"Um sinal",
          MultiploSinais:"Múltiplos sinais"
        },

        CheckboxReiniciar:"Reiniciar ciclo",
      },
      // Fim MesanielloConfig

      // GeralConfig
      geralConfig:{
        titleGerais:"Parâmetros Gerais",
        titleRecuperacao:"Parâmetros de Recuperação",

        // Restante da página no componente GlobalParamsScreen
        // Restante da página no componente LossRecoverScreen
      },
      // Fim GeralConfig

      addPhone:{
        Title:"Conectar o telegram",

        textAddTelefone:"Adicionar Telefone:",
        textAddTelegram:"Codigo do Telegram:",
        textSenhaTelegram:"Senha do Telegram:",
      }, //Fim addPhone

      // AuthCode
      authCode:{
        Subtitle:"Insira email e senha",

        labelSenha:"Senha",

        linkRegistro:"Faça o cadastro Aqui",
      }, //Fim authCode

      // BrokerConfig
      BrokerConfig:{
        Title:"Corretoras"


      }, //Fim BrokerConfig

      // BuyRobot
      buyRobot:{

        textTipoDePlano:"Tipo de plano",
        // dias
        dias:{
          dias7:"7 dias = R$25",
          dias15:"15 dias = R$37",
          dias30:"30 dias = R$67",
          dias90:"90 dias = R$167",
          dias180:"180 dias = R$300",
        },

        Title:"Pagamento",

        textMetodo:"Metodo",
        metodoItens:{
          MercadoPago:"Mercado Pago",
          Hotmart:"Hotmart",
          Pix:"Pix",
        },

        buttonGerarLink:"Gerar Link",

        linkPagamento:"Link de pagamento",

        // label
        labelIdTelegram:"Seu ID do telegram:",
        labelCPF:"CPF (Sem pontos ou traços):",
        labelVendedor:"ID do Vendedor:",

        // Alert
        messageDadosIncorretos:"Favor insira os dados corretamente!",
        messageCpf:"Favor inserir o CPF",

      }, //Fim BuyRobot

      // ChatList
      channelList:{
        messageAtualizando:"Atualizando... aguarde uns segundos",
        messageApenasTrader:"Apenas trader pode atualizar/salvar",

        title:"Grupos e Canais",

        textAguarde:"Aguarde uns segundos!",
        buttonListar:"Listar"
      },
      // Fim ChatList

      // Configure
      configure:{
        Title:"Configuração do Robô",

        linkCorretora:"Corretoras",
        linkGeral:"Configuração Geral",
        linkCopySignals:"Copy Sinais",
        linkCopyTrade:"Copy Trader",
        linkMasaniello:"Masaniello",
        linkProxy:"Configurar Proxy",
        linkComprarRobo:"Comprar o Robo",
        linkSuporte:"Suporte sonnybot"
      },
      // Fim Configure

      // ConfigureChannels
      configureChannels:{
        Title:"Configurar Salas",
      },
      // Fim configureChannels

      // CopySignals
      copySignals:{
        Title:"Configuração de Copy Sinais",

        linkAddPhone:"Adicionar Telefone",
        linkChannelList:"Adicionar Salas",
        linkConfigSala:"Configurar Salas",
        linkParametros:"Parâmetros do Leitor",
        linkCopyTrader:"Copiar Salas",

        buttonReset:"Resetar",
        buttonDeletar:"Deletar",
      },
      // Fim CopySignals

      // CopyTrader
      copyTrader:{
        TitleMain:"Configuração Copytrader",
        TitleTrader:"Configuração Trader",
        TitleSeguidor:"Configuração Seguidor",

        textHabilitar:"Habilitar copy Trader",

        textListaSeguidores:"Lista de seguidores",

        textSeguidor:"Seguidor",
        buttonRemover:"Remover",
        buttonDesativar:"Desativar",

        textOrdemSimultaneas:"Ordens Simultâneas:",
        textMinValue: "Entrada Minima:",
        textMaxValue: "Entrada Máxima:",
      },
      // Fim CopyTrader

      // CopyTraderSignals
      CopyTraderSignals:{
        subTitle:"Configuração Copy trader",

        textIdTrader:"ID do Trader:",
      }

      // Fim CopyTraderSignals
    },
    // Fim Páginas
  },
};
