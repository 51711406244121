import React, { useState, useEffect } from "react";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";

import { Button, Container, Title } from "./styles";
import { Box } from "@mui/system";
import { useUser } from "../../contexts/UserContext";

import Translator from "../../components/i18nComponents/Translator";
import { useTranslation } from "react-i18next";

export default function BuyRobot() {
  const { handleBuyRobot, paymentLink, setPaymentLink } = useUser();
  const [sellerID, setSellerID] = useState(null);
  //const [cpf, setCPF] = useState(null);
  const [subscriptionID, setSubscriptionID] = useState(null);
  const [methodID, setMethodID] = useState(null);
  const [userIDL, setUserIDL] = useState(null);
  const [opcao1, setOpcao1] = useState(null);

  const userid = localStorage.getItem("userId");
  const sellerID2 = localStorage.getItem("sellerID");

  const {t} = useTranslation()
  useEffect(() => {

    if (userid) {
      setUserIDL(userid);
    }

    if (sellerID2) {
      setSellerID(sellerID2);

    } else {
      const windowUrl = window.location.search;
      const params = new URLSearchParams(windowUrl);
      var arr = windowUrl.split('?');
      if (arr.length > 1 && arr[1] !== '') {
        var vendid = params.get('sellerid');
        if (vendid != userid) {
          setSellerID(vendid);
          localStorage.setItem("sellerID", vendid);
          console.log("sellerid = ", params.get('sellerid'));
        }
        //console.log("traderid = ", );
      }
    }

  }, []);

  const handleChange = (event) => {
    setSubscriptionID(event.target.value);
  };

  const handleMethod = (event) => {
    setMethodID(event.target.value);
    if (event.target.value == 1) {
      setOpcao1(t("paginas.buyRobot.dias.dias7"));
    } else {
      setOpcao1(t("paginas.buyRobot.dias.dias7"))
    }
  };

  const handleBuyButton = (event) => {
    if (!userIDL || !subscriptionID) {
      alert(t("paginas.buyRobot.messageDadosIncorretos"));
      return;
    }
    localStorage.setItem("userId", userIDL);
    localStorage.setItem("traderId", userIDL);
    //
    handleBuyRobot(userIDL, subscriptionID, sellerID);
  };

  return (
    <Container>
      <Title><Translator path="paginas.buyRobot.Title"/></Title>

      <FormControl sx={{ minWidth: "100%" }} size="small">
        <InputLabel id="demo-select-small"><Translator path="paginas.buyRobot.textTipoDePlano"/></InputLabel>
        <Select
          labelId="demo-select-small"
          id="demo-select-small"
          value={subscriptionID}
          label="subscription"
          onChange={handleChange}
          required
        >
          <MenuItem value={5}><Translator path="paginas.buyRobot.dias.dias7"/></MenuItem>
          <MenuItem value={1}><Translator path="paginas.buyRobot.dias.dias15"/></MenuItem>
          <MenuItem value={2}><Translator path="paginas.buyRobot.dias.dias30"/></MenuItem>
          <MenuItem value={3}><Translator path="paginas.buyRobot.dias.dias90"/></MenuItem>
          <MenuItem value={4}><Translator path="paginas.buyRobot.dias.dias180"/></MenuItem>
        </Select>
      </FormControl>

      <Box
        component="form"
        sx={{
          width: "100%",
        }}
        noValidate
        autoComplete="off"
      >
        <TextField
          required
          fullWidth
          sx={{ marginY: "15px" }}
          label={t("paginas.buyRobot.labelIdTelegram")}
          defaultValue={userIDL ? userIDL : userid}
          type="number"
          onChange={(e) => {
            setUserIDL(e.target.value);
          }}
          variant="filled"
        />

        <TextField
          fullWidth
          sx={{ marginY: "15px" }}
          label={t("paginas.buyRobot.labelVendedor")}
          value={sellerID}
          type="number"
          onChange={(e) => {
            setSellerID(e.target.value);
          }}
          variant="filled"
        />

      </Box>
      <Button onClick={handleBuyButton}><Translator path="paginas.buyRobot.buttonGerarLink"/></Button>

      {!paymentLink ? '' : <a href={paymentLink} children={t("paginas.buyRobot.linkPagamento")}  />}

    </Container>
  );
}

// 7 dias = $30, 1 mês = $100, 3 meses = $240, 6 meses = $450
