import styled from "styled-components";

export const CardContainer = styled.div`
  border: 1px solid gray;
  padding: 15px 12px;
  border-radius: 8px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  input {
    border: 0.5px solid gray;
    height: 25px;
  }
`;
export const Divider = styled.div`
  margin-top: 5px;
  margin-bottom: 15px;
  border-bottom: 1px solid gray;
`;
export const SubTitle = styled.div`
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 1.5px;
  margin-left: 8px;
  word-wrap: break-word;
`;

export const InputContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 0 5px 0;
  gap: 8px;

  input {
    border: 0.5px solid gray;
    height: 25px;
  }
`;
export const Button = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 4px 6px;
  border: 1px solid gray;
  border-radius: 4px;

  margin: 8px 0;

  cursor: pointer;

  a {
    text-decoration: none;
    color: black;
  }
`;

