import { createTheme, ThemeProvider } from "@mui/material";
import React from "react";

import MiniDrawer from "./components/MiniDrawer";
import MainRoutes from "./routes";

const darkTheme = createTheme({
  pallete: {
    mode: "dark",
  },
});

export default function App() {
  return (
    <>
      <ThemeProvider theme={darkTheme}>
        <MiniDrawer>
          <MainRoutes />
        </MiniDrawer>
      </ThemeProvider>
    </>
  );
}
