import  i18n  from "../../i18n/index"

export const errors_messages = state => ({
    email   : state?.email.trim() === '' ? i18n.t("paginas.register.config.email") : ' ',
    password: state?.password.trim() === '' ? i18n.t("paginas.register.config.password") : ' ',

    confirmPassword: [ 
        state?.confirmPassword.trim() === '' ? i18n.t("paginas.register.config.confirmPasswordNull") : ' ',
        state.password !== state.confirmPassword ? i18n.t("paginas.register.config.confirmPassword") : ' ',
    ].reduce( (acc, message) => {
        if (message !== ' ') {
            acc = message  
        }
        return acc 
    }, ' '),

    userid: state?.userid.trim() === '' ? i18n.t("paginas.register.config.confirmPassword") : ' ',
        
})    


const errors = {
    email          : ' ', 
    password       : ' ', 
    confirmPassword: ' ',
    userid         : ' '
}

export const initialState = {
    
    email          : '', 
    password       : '',
    confirmPassword: '', 
    userid         : '', 
    traderid       : '', 
    errors
 
}