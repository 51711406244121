import React, { useState } from "react";
import { TextField } from "@mui/material";
import { Box } from "@mui/system";
import { Button, CardContainer, Container, Divider, Title } from "./styles";
import { useUser } from "../../contexts/UserContext";

import Translator from "../../components/i18nComponents/Translator"

export default function AddPhone() {

  const { handlePhone, addLoginCode } = useUser();

  const [phoneOk, setPhoneOk] = useState(false);
  const [phone, setPhone] = useState(false);
  const [loginCode, setLoginCode] = useState(null);
  const [password, setPassword] = useState(null);

  const userid = localStorage.getItem("userId");

  const handleAddPhone = async (phoneParam) => {

    const response = await handlePhone(phoneParam, userid);

    if (response) setPhoneOk(true);
  };

  const handleAddUser = async (codeParam, passwordParam) => {
    await addLoginCode(codeParam, passwordParam, userid);
    setTimeout(() => {
      window.location.href = "/copysignals";
    }, 1000);
  };

  return (
    <Container>
      <Title><Translator path="paginas.addPhone.Title"/></Title>
      <Divider />

      <CardContainer>
        {!phoneOk && (
          <>
            <div><Translator path="paginas.addPhone.textAddTelefone"/></div>
            <Box
              component="form"
              sx={{
                maxWidth: "100%",
              }}
              noValidate
              autoComplete="off"
            >
              <TextField
                fullWidth
                sx={{ marginBottom: "15px" }}
                placeholder={"+5511989287670"}
                onChange={(e) => {
                  setPhone(e.target.value.trim());
                }}
                variant="filled"
              />
            </Box>

            <Button onClick={() => handleAddPhone(phone)}><Translator path="buttonEnviar"/></Button>
          </>
        )}
        {phoneOk && (
          <>
            <div><Translator path="paginas.addPhone.textAddTelegram"/></div>
            <Box
              component="form"
              sx={{
                maxWidth: "100%",
              }}
              noValidate
              autoComplete="off"
            >
              <TextField
                fullWidth
                sx={{ marginBottom: "15px" }}
                onChange={(e) => {
                  setLoginCode(e.target.value.trim());
                }}
                variant="filled"
              />
            </Box>

            <div><Translator path="paginas.addPhone.textSenhaTelegram"/></div>
            <Box
              component="form"
              sx={{
                maxWidth: "100%",
              }}
              noValidate
              autoComplete="off"
            >
              <TextField
                fullWidth
                sx={{ marginBottom: "15px" }}
                onChange={(e) => {
                  setPassword(e.target.value.trim());
                }}
                variant="filled"
              />
            </Box>

            <Button onClick={() => handleAddUser(loginCode, password)}>Enviar</Button>
          </>
        )}
      </CardContainer>
    </Container>
  );
}
