export const options = (request) => ({
    method: "POST",
    body: JSON.stringify({
        email   : request.email   ,
        password: request.password,
        userid  : request.userid  , 
        traderid: request.traderid, 
        sellerid: request.sellerid,
        uitoken: "4c1d09785d8334b443aae16acda90ee11b2f74ba86c8fcc39ab718befc04c101"
    }),
})