import React from "react";

import { Button, Container, Divider, Title } from "./styles";
import Translator from "../../components/i18nComponents/Translator";

export default function NotFound() {
  return (
    <Container>
      <Button>
        <a href="/"><Translator path="paginas.notFound.buttonHome"/></a>
      </Button>
      <Divider />
      <Title><Translator path="paginas.notFound.Title"/></Title>
    </Container>
  );
}
