import styled from "styled-components";

export const SubTitle = styled.div`
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 1.5px;
  margin-left: 8px;
  word-break: break-all;
`;

export const CardContainer = styled.div`
  width: 270px;
  border: 1px solid gray;
  padding: 15px 30px;
  border-radius: 8px;

  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  input {
    border: 0.5px solid gray;
    height: 25px;
  }
`;
