import React, { useEffect, useState } from "react";

import {
  Container,
  Title,
  Divider,
  Button,
} from "./styles";

import Translator from "../../components/i18nComponents/Translator";

export default function CopyTrader() {

  return (
    <Container>
      <Title><Translator path="paginas.copyTrader.TitleMain"/></Title>
      <Divider />

      <Button>
        <a href="/traderconfig"><Translator path="paginas.copyTrader.TitleTrader"/></a>
      </Button>

      <Button>
        <a href="/configfollow"><Translator path="paginas.copyTrader.TitleSeguidor"/></a>
      </Button>
      </Container>
  );
}
