import styled from "styled-components";

export const Container = styled.div``;

export const Divider = styled.div`
  margin-top: 5px;
  margin-bottom: 15px;
  border-bottom: 1px solid gray;
`;
export const Title = styled.div`
  font-size: 1.5rem;
  font-weight: bold;
  letter-spacing: 1.5px;
  margin-bottom: 20px;
`;

export const SubContainer = styled.div`
  display: grid;
  gap: 10px;
  margin-bottom: 20px;

  @media (min-width: 375px) {
    grid-template-columns: 270px;
  }
  @media (min-width: 750px) {
    grid-template-columns: 270px 270px;
  }
  @media (min-width: 1070px) {
    grid-template-columns: 270px 270px 270px;
  }
  @media (min-width: 1395px) {
    grid-template-columns: 270px 270px 270px 270px;
  }
`;