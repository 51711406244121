import styled from "styled-components";

export const Container = styled.div``;
export const ChannelContainer = styled.div`
  display: grid;
  gap: 10px;
  margin-bottom: 20px;

  @media (min-width: 375px) {
    grid-template-columns: 275px;
  }
  @media (min-width: 720px) {
    grid-template-columns: 275px 275px;
  }
  @media (min-width: 970px) {
    grid-template-columns: 275px 275px 275px;
  }
  @media (min-width: 1244px) {
    grid-template-columns: 275px 275px 275px 275px;
  }
`;

export const Title = styled.div`
  font-size: 30px;
  font-weight: bold;
  letter-spacing: 1.5px;
  margin-bottom: 20px;
`;
export const Divider = styled.div`
  margin-top: 5px;
  margin-bottom: 15px;
  border-bottom: 1px solid gray;
`;
