import { useTranslation } from "react-i18next";
import Translator from "../i18nComponents/Translator";

import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import AutoGraphIcon from "@mui/icons-material/AutoGraph";
import RssFeedIcon from "@mui/icons-material/RssFeed";
import SettingsIcon from "@mui/icons-material/Settings";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import HomeIcon from "@mui/icons-material/Home";
import LoginIcon from "@mui/icons-material/Login";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { useUser } from "../../contexts/UserContext";
import SwitchFlags from "../i18nComponents/SwitchFlags";

const drawerWidth = 240;



const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

// [{"userid":"824106680","traderid":"824106680"},{"userid":"1247199848","traderid":"1247199848"}]
export default function MiniDrawer({ children }) {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [botID, setBotID] = React.useState(false);
  const userid = localStorage.getItem("userId");
  const traderid = localStorage.getItem("traderId");
  const botIDs = JSON.parse(localStorage.getItem("botIDs"));
  const {t} = useTranslation()
  //console.log("botIDs type = ", typeof botIDs);
  //console.log('botIDs = ', botIDs);


  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleBotID = (event) => {
    setBotID(event.target.value);
    //console.log(event.target.value);
    var val = event.target.value;
    var index = botIDs.findIndex(function(item, i){
      return item.userid === val
    });

    //console.log("select = ", botIDs[index].userid);
    localStorage.setItem("userId", botIDs[index].userid);
    localStorage.setItem("traderId", botIDs[index].traderid);
    window.location.reload();
  };

  let textVar;
  if (userid && traderid)
    textVar = `Usuario - ${userid},${traderid}`;
  else
    textVar = t('componentes.MiniDrawer.textUser');

  return (
    <Box sx={{ display: "flex" }}>
      
      <CssBaseline />

      <AppBar position="fixed" open={open}>
        <Toolbar>
          
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 5,
              ...(open && { display: "none" }),
            }}
          >
          <MenuIcon />
          </IconButton>
          
          <Typography variant="h7" noWrap component="div">
            {textVar}<br />
            
            {botIDs != null && Object.keys(botIDs).length > 1 && (
              
                <>
            <FormControl sx={{ minWidth: "50%" }} size="small">
            <InputLabel id="botID-sel"><Translator path="componentes.MiniDrawer.textRobo"/></InputLabel>
            <Select
              labelId="botID-sel"
              id="botID-sel"
              value={botID ? botID : userid}
              label="Bot ID"
              onChange={handleBotID}
              required
            >

              {botIDs.map((botIDUser) => (
                  <MenuItem value={botIDUser.userid}>{botIDUser.userid}</MenuItem>
                ))}
            </Select>
          </FormControl>
            </>
              )}

          </Typography>
          <SwitchFlags/>
        </Toolbar>

      </AppBar>

      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />

        <List>
          <ListItem key={"Homepage"} disablePadding sx={{ display: "block" }}>
            <ListItemButton
              href="/"
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <HomeIcon />
              </ListItemIcon>

              <ListItemText
                primary={"Homepage"}
                sx={{ opacity: open ? 1 : 0 }}
              />
            </ListItemButton>
          </ListItem>
          <Divider />

          <ListItem
            key={"Configurações"}
            disablePadding
            sx={{ display: "block" }}
          >
            <ListItemButton
              href="/geralconfig"
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <SettingsIcon />
              </ListItemIcon>
              <ListItemText
                primary={t("componentes.MiniDrawer.linksConfig")}
                sx={{ opacity: open ? 1 : 0 }}
              />
            </ListItemButton>
          </ListItem>
          <Divider />

          <ListItem
            key={"Copy Trader"}
            disablePadding
            sx={{ display: "block" }}
          >
            <ListItemButton
              href="/copytrader"
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <AutoGraphIcon />
              </ListItemIcon>
              <ListItemText
                primary={"Copy Trader"}
                sx={{ opacity: open ? 1 : 0 }}
              />
            </ListItemButton>
          </ListItem>
          <Divider />

          <ListItem
            key={"Copy Sinais"}
            disablePadding
            sx={{ display: "block" }}
          >
            <ListItemButton
              href="/copysignals"
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <RssFeedIcon />
              </ListItemIcon>
              <ListItemText
                primary={"Copy Sinais"}
                sx={{ opacity: open ? 1 : 0 }}
              />
            </ListItemButton>
          </ListItem>
          <Divider />

          <ListItem
            key={"Comprar/Renovar Robo"}
            disablePadding
            sx={{ display: "block" }}
          >
            <ListItemButton
              href="/comprar"
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <ShoppingCartIcon />
              </ListItemIcon>
              <ListItemText
                primary={t("componentes.MiniDrawer.linksComprarRobo")}
                sx={{ opacity: open ? 1 : 0 }}
              />
            </ListItemButton>
          </ListItem>
          <Divider />

          <ListItem
            key={"Login"}
            disablePadding
            sx={{ display: "block" }}
          >
            <ListItemButton
              href="/auth"
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <LoginIcon />
              </ListItemIcon>
              <ListItemText
                primary={"Login"}
                sx={{ opacity: open ? 1 : 0 }}
              />
            </ListItemButton>
          </ListItem>
          <Divider />
        </List>
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <DrawerHeader />
        {children}
      </Box>
    </Box>
  );
}
