import React, { useEffect, useState } from "react";

import { Grow } from "@mui/material";
import { useUser } from "../../contexts/UserContext";
import {
  Button,
  ButtonContainer,
  ChannelContainer,
  Container,
  Divider,
  Title,
} from "./styles";
import ChatCard from "../../components/ChatCard";
import Snackbar from "../../components/Snackbar";

import Translator from "../../components/i18nComponents/Translator";
import { useTranslation } from "react-i18next";

export default function ChannelList() {

  const { handleSaveChatList, handleGetChatList, handleUpdateChatList, listChannels } = useUser();
  const [saveStateOpen, setSaveStateOpen] = useState(false);
  const [updateStateOpen, setUpdateStateOpen] = useState(false);
  const userid = localStorage.getItem("userId");
  const traderid = localStorage.getItem("traderId");
  const {t} =useTranslation()

  useEffect(() => {
    handleGetChatList();
  }, []);

  const handleButtons = async (btn) => {
    if (userid == traderid) {
      if (btn === 1) {
        handleSaveChatList();
        setSaveStateOpen((prevState) => !prevState);
        setTimeout(() => {
          window.location.href = "/copysignals";
        }, 2000);
      }
      else {
        handleUpdateChatList();
        //setUpdateStateOpen((prevState) => !prevState);
        alert(t("paginas.channelList.messageAtualizando"));
        //window.location.reload();
        setTimeout(() => {
            window.location.href = "/channels-list";
        }, 2000);
      }
    } else {
      alert(t("paginas.channelList.messageAtualizando"));
    }
  };

  return (
    <Container>
      <Title><Translator path="paginas.channelList.title"/></Title>
      <Divider />
      <ButtonContainer>

      <Snackbar
        type="success"
        open={saveStateOpen}
        onClose={() => setSaveStateOpen(false)}
      >
        <Translator path="snackbarSucess"/>
      </Snackbar>

      <Snackbar
        type="info"
        open={updateStateOpen}
        onClose={() => setUpdateStateOpen(false)}
      >
        <Translator path="paginas.channelList.textAguarde"/>
      </Snackbar>

        <Button onClick={() => handleButtons(1)}><Translator path="buttonSalvar"/></Button>
        <Button onClick={() => handleButtons(2)}><Translator path="paginas.channelList.buttonListar"/></Button>
      </ButtonContainer>
      <Divider />

      {listChannels && listChannels.length > 0 && (
        <Grow
          in={listChannels}
          style={{ transformOrigin: "0 0 0" }}
          {...(listChannels.length > 0 ? { timeout: 1000 } : {})}
        >
          <ChannelContainer>
            {listChannels.map((channel, index) => (
              <ChatCard data={channel} key={index} />
            ))}
          </ChannelContainer>
        </Grow>
      )}
    </Container>
  );
}
