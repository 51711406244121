import React, { useEffect, useState } from "react";

import { CardContainer, SubTitle } from "./styles";

import { useUser } from "../../contexts/UserContext";

export default function ChatCard({ data }) {
  const { addChatOnList, removeOnList, chatList } = useUser();

  const [checkedTrader, setCheckedTrader] = useState(false);

  const handleChange = async () => {
    if (checkedTrader) {
      await removeOnList(data.chid);
    } else {
      await addChatOnList(data.chname, data.chid);
    }
    setCheckedTrader(!checkedTrader);
  };

  useEffect(() => {
    //console.log(chatList);
  }, [chatList]);

  return (
    <CardContainer>
      <input type="checkbox" checked={checkedTrader} onChange={handleChange} />

      <SubTitle>{data.chname}</SubTitle>
    </CardContainer>
  );
}
