import styled from "styled-components";

export const SubContainer = styled.div`
  display: grid;
  gap: 10px;
  margin-bottom: 20px;

  @media (min-width: 375px) {
    grid-template-columns: 270px;
  }
  @media (min-width: 750px) {
    grid-template-columns: 270px 270px;
  }
  @media (min-width: 1070px) {
    grid-template-columns: 270px 270px 270px;
  }
  @media (min-width: 1395px) {
    grid-template-columns: 270px 270px 270px 270px;
  }
`;
export const TraiderContainer = styled.div`
  display: flex;
  gap: 10px;
`;
export const CardContainer = styled.div`
  border: 1px solid gray;
  padding: 15px 30px;
  border-radius: 8px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
export const SubTitle = styled.div`
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 1.5px;
  margin-left: 8px;
`;

export const Divider = styled.div`
  margin-top: 5px;
  margin-bottom: 15px;
  border-bottom: 1px solid gray;
`;

export const Button = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 60px;
  padding: 2px 6px;
  border: 1px solid gray;
  border-radius: 4px;

  cursor: pointer;
`;
