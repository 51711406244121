import React, { useState } from "react";

import { Box } from "@mui/system";
import { Checkbox, TextField, Select, MenuItem } from "@mui/material";
import { useUser } from "../../contexts/UserContext";
import Snackbar from "../../components/Snackbar";
import Translator from "../i18nComponents/Translator"
import {
  Button,
  CardContainer,
  Divider,
  InputContainer,
  SubTitle,
} from "./styles";
import { useTranslation } from "react-i18next";

export default function ChannelCard({ channel }) {
// Tradução i18n
const {t} = useTranslation()
//--------
  const { handleChannConfig, handleChannelRemove } = useUser();

  const [enable, setEnable] = useState(channel.chanEnable);
  const [lote, setLote] = useState(channel.lote);
  const [galenum, setGalenum] = useState(channel.galenum);
  const [galenumFat, setGalenumFat] = useState(channel.galefat);
  const [wordFilter, setWordFilter] = useState(channel.wordfilter);
  const [ignoreWord, setIgnore] = useState(channel.ignore);
  const [delaySig, setDelaySig] = useState(channel.delaysig);
  const [signalType, setSignalType] = useState(channel.signaltype);
  const [brokerID, setBrokerID] = useState(channel.brokerid);
  const [timeframe, setTimeframe] = useState(channel.timeframe);
  const [saveStateOpen, setSaveStateOpen] = useState(false);
  //const [updateStateOpen, setUpdateStateOpen] = useState(false);
  const userid = localStorage.getItem("userId");
  const traderid = localStorage.getItem("traderId");

  const handleSave = async (btn) => {

    if (btn === 1) {
      //
      await handleChannConfig({
        chanEnable: enable,
        lote: lote,
        galenum: galenum,
        galefat: galenumFat,
        idNum: channel.idNum,
        wordfilter: wordFilter,
        delaysig: delaySig,
        signaltype: signalType,
        brokerid: brokerID,
        ignore: ignoreWord,
        timeframe: timeframe,
      });

      setSaveStateOpen((prevState) => !prevState);

    } else {
      //
      if (userid === traderid) {
        //
        handleChannelRemove(channel.idNum);
        setSaveStateOpen((prevState) => !prevState);

        window.location.reload();

      } else {
        // i18n
        const translatedMessage = t('componentes.ChannelCardComponente.handleSaveElse');
        alert(translatedMessage);
      }
    }
  };

  const handleSignal = (event) => {
    setSignalType(event.target.value);
  };

  const handleBrokerID = (event) => {
    setBrokerID(event.target.value);
  };

  // "userid": "123456",
  //   "chanEnable": true,
  //   "lote": 2.5,
  //   "galenum": 1,
  //   "galefat": 2.2,
  //   "idNum": -1001246118651,
  //   "listsEn": false,

  return (
    <CardContainer>
      <SubTitle>{channel.name}</SubTitle>
      <Divider />

      <InputContainer>
        <Checkbox
          checked={enable}
          name={"chanEnable"}
          id={"idEn"}
          onChange={() => setEnable(!enable)}
        />
        <div>{t("componentes.ChannelCardComponente.textCheckox")}</div>
      </InputContainer>

      <Box
        component="form"
        sx={{
          maxWidth: "100%",
        }}
        noValidate
        autoComplete="off"
      >
        <TextField
          fullWidth
          sx={{ marginBottom: "15px" }}
          id="idLote"
          name="lote"
          label={t('componentes.ChannelCardComponente.TextFieldEntrada')}
          type="number"
          defaultValue={lote}
          onChange={(e) => {
            setLote(e.target.value);
          }}
          variant="filled"
        />

        <TextField
          fullWidth
          sx={{ marginBottom: "15px" }}
          id="idGale"
          name="galenum"
          label="Martingale: "
          type="number"
          defaultValue={galenum}
          onChange={(e) => {
            setGalenum(e.target.value);
          }}
          variant="filled"
        />

        <TextField
          fullWidth
          sx={{ marginBottom: "15px" }}
          id="idGale"
          name="galenumfat"
          label={t('componentes.ChannelCardComponente.TextFieldMultiplicador')}
          type="number"
          defaultValue={galenumFat}
          onChange={(e) => {
            setGalenumFat(e.target.value);
          }}
          variant="filled"
        />

        {userid === traderid && (
        <TextField
          fullWidth
          sx={{ marginBottom: "15px" }}
          id="idDelay"
          name="delaysig"
          label="Delay: "
          type="text"
          defaultValue={delaySig}
          onChange={(e) => setDelaySig(e.target.value)}
          variant="filled"
        />
        )}

        {userid === traderid && (
        <TextField
          fullWidth
          sx={{ marginBottom: "15px" }}
          id="idFilter"
          name="wordfilter"
          label={t('componentes.ChannelCardComponente.TextFieldFiltro')}
          type="text"
          defaultValue={wordFilter}
          onChange={(e) => setWordFilter(e.target.value)}
          variant="filled"
        />
        )}

        {userid === traderid && (
        <TextField
          fullWidth
          sx={{ marginBottom: "15px" }}
          label={t('componentes.ChannelCardComponente.TextFieldIgnorar')}
          type="text"
          defaultValue={ignoreWord}
          onChange={(e) => setIgnore(e.target.value)}
          variant="filled"
        />
        )}

      {userid === traderid && (
        <TextField
          fullWidth
          sx={{ marginBottom: "15px" }}
          label="Timeframe: "
          type="text"
          defaultValue={timeframe}
          onChange={(e) => setTimeframe(e.target.value)}
          variant="filled"
        />
        )}

      </Box>

      {userid === traderid && (
      <Select
          labelId="demo-select-small"
          value={signalType}
          label={t('componentes.ChannelCardComponente.SelectSinalTipo.labelSinalTipo')}
          defaultValue={1}
          onChange={handleSignal}
          required
        >
          <MenuItem value={1}><Translator path="componentes.ChannelCardComponente.SelectSinalTipo.MenuItens.MenuItem1"/></MenuItem>
          <MenuItem value={2}><Translator path="componentes.ChannelCardComponente.SelectSinalTipo.MenuItens.MenuItem2"/></MenuItem>
          <MenuItem value={3}><Translator path="componentes.ChannelCardComponente.SelectSinalTipo.MenuItens.MenuItem3"/></MenuItem>
          <MenuItem value={4}><Translator path="componentes.ChannelCardComponente.SelectSinalTipo.MenuItens.MenuItem4"/></MenuItem>
          <MenuItem value={5}><Translator path="componentes.ChannelCardComponente.SelectSinalTipo.MenuItens.MenuItem5"/></MenuItem>
          <MenuItem value={6}><Translator path="componentes.ChannelCardComponente.SelectSinalTipo.MenuItens.MenuItem6"/></MenuItem>
          <MenuItem value={7}><Translator path="componentes.ChannelCardComponente.SelectSinalTipo.MenuItens.MenuItem7"/></MenuItem>
          <MenuItem value={8}><Translator path="componentes.ChannelCardComponente.SelectSinalTipo.MenuItens.MenuItem8"/></MenuItem>
        </Select>
      )}

      {userid === traderid && (
        <Select
          labelId="demo-select-small"
          value={brokerID}
          label={t('componentes.ChannelCardComponente.SelectCorretora.labelCorretora')}
          defaultValue={0}
          onChange={handleBrokerID}
          required
        >
          <MenuItem value={0}><Translator path="componentes.ChannelCardComponente.SelectCorretora.MenuItens.MenuItem1"/></MenuItem>
          <MenuItem value={1}><Translator path="componentes.ChannelCardComponente.SelectCorretora.MenuItens.MenuItem2"/></MenuItem>
          <MenuItem value={2}><Translator path="componentes.ChannelCardComponente.SelectCorretora.MenuItens.MenuItem3"/></MenuItem>
          <MenuItem value={3}><Translator path="componentes.ChannelCardComponente.SelectCorretora.MenuItens.MenuItem4"/></MenuItem>
          <MenuItem value={4}><Translator path="componentes.ChannelCardComponente.SelectCorretora.MenuItens.MenuItem5"/></MenuItem>
          <MenuItem value={5}><Translator path="componentes.ChannelCardComponente.SelectCorretora.MenuItens.MenuItem6"/></MenuItem>
          <MenuItem value={6}><Translator path="componentes.ChannelCardComponente.SelectCorretora.MenuItens.MenuItem7"/></MenuItem>
          <MenuItem value={7}><Translator path="componentes.ChannelCardComponente.SelectCorretora.MenuItens.MenuItem8"/></MenuItem>
          <MenuItem value={8}><Translator path="componentes.ChannelCardComponente.SelectCorretora.MenuItens.MenuItem9"/></MenuItem>
          <MenuItem value={9}><Translator path="componentes.ChannelCardComponente.SelectCorretora.MenuItens.MenuItem10"/></MenuItem>
          <MenuItem value={10}><Translator path="componentes.ChannelCardComponente.SelectCorretora.MenuItens.MenuItem11"/></MenuItem>
          <MenuItem value={11}><Translator path="componentes.ChannelCardComponente.SelectCorretora.MenuItens.MenuItem12"/></MenuItem>
          <MenuItem value={12}><Translator path="componentes.ChannelCardComponente.SelectCorretora.MenuItens.MenuItem13"/></MenuItem>
          <MenuItem value={13}><Translator path="componentes.ChannelCardComponente.SelectCorretora.MenuItens.MenuItem14"/></MenuItem>
        </Select>
      )}

      <Snackbar
        type="success"
        open={saveStateOpen}
        onClose={() => setSaveStateOpen(false)}
      >
        <Translator path="componentes.ChannelCardComponente.TextoSalvo"/>
      </Snackbar>

      <Button onClick={() => handleSave(1)}><Translator path="buttonSalvar"/></Button>
      <Button onClick={() => handleSave(2)}><Translator path="componentes.ChannelCardComponente.ButtonRemover"/></Button>
    </CardContainer>
  );
}
